<p class="pt-4 pb-4 bgcolor-blue text-center font-weight-bold color-white text-uppercase overflow-visible"> {{
    "Accounting.invoice.paythis" | translate }}</p>
<div mat-dialog-content class="p-0">
    <iframe [src]="data.iframeSrc"
        referrerpolicy="origin" width="100%" height="100%" class="noframe iframe-class" scrolling="yes" frameBorder="0">
    </iframe>
</div>
<div mat-dialog-actions class="mb-2 d-flex align-items-center justify-content-between">
    <div class="ml-5 eButton height-40 cancel pointer d-flex align-items-center justify-content-center"
        (click)="dialogRef.close(true)">
        <span>{{ 'close' | translate }}</span>
</div>
