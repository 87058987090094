
<h4 mat-dialog-title class="p-4 header bgcolor-blue color-white font-size-18 text-center">
    {{ "Accounting.payment.paymentMethod.paymentInformation" | translate }}
</h4>
<div  class="form-container pl-4 pr-4 content pt-2 pb-2 m-0 position-relative">
    <mat-radio-group aria-labelledby="issuing-bank" class="issuing-bank-radio-group mt-4" [(ngModel)]="selectedIssuingBank">
        <ng-container *ngFor="let bank of issuingBank">
            <mat-radio-button class="issuing-bank-radio-button" [value]="bank">{{ ('Accounting.payment.paymentMethod.' + bank) | translate }}</mat-radio-button>
        </ng-container>
    </mat-radio-group>
</div>
<div mat-dialog-actions class="mt-0 mb-0 pl-3 pr-3 pt-2 pb-4">
    <div class="buttons d-flex align-items-center justify-content-between">
        <div class="button-component bgcolor-white" (click)="dialogRef.close(false)">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="button-component disabled pointer" (click)="onSubmit()" [ngClass]="{'cursor-pointer bgcolor-blue' : selectedIssuingBank}">
            <div class="inner user-no-select inner-disabled pb-2 pt-2 pl-4 pr-4" [class.inner-blue]="selectedIssuingBank">
                <span class="color-white ml-0">{{ 'next' | translate }}</span>
            </div>
        </div>
    </div>
</div>
