<div mat-dialog-content class="h-100">
    <iframe [src]="data.iframeSrc"
        referrerpolicy="origin" width="100%" height="100%" class="noframe iframe-class" scrolling="yes" frameBorder="0"
        sandbox="allow-scripts allow-forms allow-same-origin allow-popups">
    </iframe>
    <p class="btn-close font-size-24">
        <span class="mr-3 pointer" (click)="onCancel()">
            <i class="icon-cts-action-close-wbg" ></i>
        </span>
    </p>
</div>
