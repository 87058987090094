import {Component, OnInit, ElementRef, ViewChild, Renderer2, Output, EventEmitter, HostListener, Input } from "@angular/core";
import { country } from "./codes";

interface flag {
    code: string;
    dial_code: string;
    name: string;
}

@Component({
    selector: "app-phone-number-input",
    templateUrl: "./phone-number-input.component.html",
    styleUrls: ["./phone-number-input.component.css"]
})
export class PhoneNumberInputComponent implements OnInit {
    flags: flag[] = [];
    countryDetails: flag[] = country;
    selectedFlag: flag;
    allowed: flag[] = [];

    @Input() defaultCountry: string;
    @Input() allowedCountries: string[];
    @Input() phonenumber: any;

    @Output("onPressNumber") onPressNumber: EventEmitter<any> = new EventEmitter();
    @ViewChild("flagDropdown", { read: ElementRef }) flagDropdown: ElementRef;

    @HostListener("window:click", ["$event"])
    onClick(event) {
        const className = event.target.className;
        if (!className.includes("dropbtn")) {
            if (!event.target.className.includes("search-form-control"))
                this.render.removeClass(this.flagDropdown.nativeElement, "show");
        }
    }

    constructor(private render: Renderer2) { }

    ngOnInit() {
        this.formatFlags();
        this.allowed = this.flags.filter(f => this.allowedCountries.indexOf(f.code) !== -1);
        if (this.phonenumber && this.phonenumber.dial_code) {
            this.selectedFlag = this.flags.find(f => f.dial_code === this.phonenumber.dial_code);
            this.selectedFlag.dial_code = this.phonenumber.dial_code;
        } else {
            this.selectedFlag = this.flags.find(f => f.code === this.defaultCountry);
            this.phonenumber.dial_code = this.selectedFlag.dial_code;
            this.phonenumber.tel = null;
        }

    }

    onDropDownClick() {
        if (this.flagDropdown.nativeElement.classList.contains("show")) {
            this.render.removeClass(this.flagDropdown.nativeElement, "show");
        } else {
            this.formatFlags();
            this.render.addClass(this.flagDropdown.nativeElement, "show");
        }
    }

    onFlageSelect(f) {
        this.selectedFlag = f;
        this.render.removeClass(this.flagDropdown.nativeElement, "show");
    }

    onNumberPress(v: string) {
        if (v) {
            this.onPressNumber.emit({
                dial_code: this.selectedFlag.dial_code,
                tel: v,
            });
        } else {
            this.onPressNumber.emit({
                dial_25code: this.selectedFlag.dial_code,
                tel: null
            });
        }
    }

    search(v) {
        this.formatFlags(v);
    }

    private formatFlags(v?: string) {
        this.flags = this.countryDetails
            .filter(f => {
                if (v) {
                    const s = v.trim().toLowerCase();
                    return (
                        f.code.toLowerCase().includes(s) ||
                        f.dial_code.includes(s) ||
                        f.name.toLowerCase().includes(s)
                    );
                }
                return f;
            });
    }
}