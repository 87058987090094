import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

import { Invoice } from '../../model/accounting/invoice.model';

import { EndpointsService } from 'app/service/endpoints.service';
import { Subject } from 'rxjs';

@Injectable()
export class AccountingService {

    private invoiceListSubject = new BehaviorSubject<Invoice[]>([]);
    private invoiceHuListSubject = new BehaviorSubject<Invoice[]>([]);
    private invoiceCargoBoxListSubject = new BehaviorSubject<Invoice[]>(null);
    private subscriptionInvoiceListSubject = new BehaviorSubject<Invoice[]>([]);

    public invoiceList$: Observable<Invoice[]> = this.invoiceListSubject.asObservable();
    public invoiceHuList$: Observable<Invoice[]> = this.invoiceHuListSubject.asObservable();
    public invoiceCargoBoxList$: Observable<Invoice[]> = this.invoiceCargoBoxListSubject.asObservable();
    public subscriptionInvoiceList$: Observable<Invoice[]> = this.subscriptionInvoiceListSubject.asObservable();
    public spinnerSubject: Subject<boolean> = new Subject();

    public payAllSubject: Subject<number> = new Subject();
    public recurencyInfoSubject = new Subject();
    public INVOICE_ACCOUNTING = 0;
    public INVOICE_ROADTAX = 1;
    public HUGO_CUSTOM_INVOICE = 2;
    public SUBSCRIPTIONS_INVOICE = 5;
    public CARGOBOX_CUSTOM_INVOICE = 7;

    public static TYPE_PAYMENT_BT = 1;
    public static TYPE_PAYMENT_EUPLATESC_REGULAR = 3;

    constructor(private httpClient: HttpClient, private endpointsService: EndpointsService, public translateService: TranslateService) { }

    public getInvoices(custom1: number) {
        this.spinnerSubject.next(true);
        this.httpClient.get<any[]>(this.endpointsService.get('accounting.invoicelist', [custom1]))
            .subscribe(
                {
                    next: (invoices: Invoice[]) => {
                        switch (custom1) {
                            case this.HUGO_CUSTOM_INVOICE:
                                this.invoiceHuListSubject.next(invoices);
                                break;
                            case this.CARGOBOX_CUSTOM_INVOICE:
                                this.invoiceCargoBoxListSubject.next(invoices);
                                break;
                            case this.SUBSCRIPTIONS_INVOICE:
                                this.subscriptionInvoiceListSubject.next(invoices);
                                break;
                            default:
                                this.invoiceListSubject.next(invoices);
                        }

                        this.spinnerSubject.next(false);
                    },
                    error: (error) => console.error(error)
                }
            );
    }

    public addInvoiceToTheList(invoice: Invoice, invoiceType = this.INVOICE_ACCOUNTING): void {
        switch (invoiceType) {
            case this.HUGO_CUSTOM_INVOICE:
                const invoicesHu = this.invoiceHuListSubject.getValue();
                const invoiceHuList = [invoice, ...invoicesHu.slice(0)];
                this.invoiceHuListSubject.next(invoiceHuList);
                break;
            case this.CARGOBOX_CUSTOM_INVOICE:
                const invoicesCargBox = this.invoiceCargoBoxListSubject.getValue();
                const invoiceCargoBoxList = [invoice, ...invoicesCargBox.slice(0)];
                this.invoiceCargoBoxListSubject.next(invoiceCargoBoxList);
                break;
            case this.SUBSCRIPTIONS_INVOICE:
                const subscriptionInvoices = this.subscriptionInvoiceListSubject.getValue();
                const subscriptionInvoiceList = [invoice, ...subscriptionInvoices.slice(0)];
                this.subscriptionInvoiceListSubject.next(subscriptionInvoiceList);
                break;
            default:
                const invoices = this.invoiceListSubject.getValue();
                const invoiceList = [invoice, ...invoices.slice(0)];
                this.invoiceListSubject.next(invoiceList);
                break
        }
    }

    public downloadInvoice(invoiceId: number) {
        return this.httpClient.get<any>(this.endpointsService.get('accounting.invoicedownload', [invoiceId]));
    }

    public registerPaymentInvoice(invoices: Invoice[], cardIssuer: number | null = null) {
        const invoiceIds = invoices.map((inv) => inv.id);
        return this.httpClient.post<any>(this.endpointsService.get('accounting.initpayment'), { invoice_ids: invoiceIds, card_issuer: cardIssuer });
    }

    public activateRecurencyAndRegeneratePaymentUrl(recurencyInfo: any) {
        return this.httpClient.post<any>(this.endpointsService.get('accounting.regeneratePaymentUrl'), recurencyInfo);
    }

    public getTranslationFor(label: string, value = {}): String {
        let result = '';
        this.translateService.get(label, value).subscribe((response: string) => result = response);
        return result;
    }

    public statusInvoice(id: number, paymentUrl: string) {
        return this.httpClient.post<any>(this.endpointsService.get('accounting.invoiceStatus', [id]), { payment_url: paymentUrl });
    }

    public recurencyStatus(custom1) {
        this.httpClient.get<any[]>(this.endpointsService.get('accounting.recurencyStatus', [custom1]))
            .subscribe(
                (result: any[]) => {
                    this.recurencyInfoSubject.next(result);
                }
            );
    }

    public disableRecurency(id: number) {
        return this.httpClient.post<any>(this.endpointsService.get('accounting.disableRecurency', [id]), []);
    }

    public getCargoboxInvoicesComputeCPCBTotal(): number | false {
        let initialValue = 0;
        const serial = 'CPCB';
        const invoicesToatlSum = this.invoiceCargoBoxListSubject.getValue()
            .filter(inv => inv.serial.toLocaleLowerCase() === serial.toLocaleLowerCase() && inv.payment_status !== 2)
            .reduce((accumulator, currentValue) => accumulator + +currentValue.top_up_amount, initialValue );

        return invoicesToatlSum ? invoicesToatlSum : false;
    }
}
