<div class="title-bar d-flex align-items-center">
    <p class="m-0 mr-auto">
        {{ (geofence.id ? 'Settings.geofenceTab.editGeofenceButton' : 'Settings.geofenceTab.addGeofenceButton') |
        translate }}
    </p>
    <i *ngIf="geofence.id && !isDialog" (click)="onDelete()"
        class="mr-2 icon-tmss-list-action-delete font-size-18 pointer"></i>
    <i (click)="onCancel()" *ngIf="!isDialog"
        class="cursor-pointer icon-cts-action-close-wbg color-blue font-size-34"></i>
</div>
<div class="form-container"
    [ngStyle]="{'height': isDialog ? 'auto' : 'calc(100% - 164px)', 'overflow': isDialog ? 'auto' : 'scroll'}">
    <div class="ng-select-wrapper geofence flex-grow-1 mt-4" [ngClass]="{ 'ng-select-wrapper-disabled' : isDialog }">
        <ng-select placeholder="{{ (isDialog ? '' : 'Settings.geofenceTab.geofenceType') | translate }}"
            class="flex-grow-1" [disabled]="isDialog" appendTo="body" [(ngModel)]="geofence.type"
            (change)="geofenceTypeOnChange($event)" [clearable]="false">
            <ng-option *ngFor="let option of geofenceTypes" [value]="option">
                {{ option }}
            </ng-option>
        </ng-select>
    </div>
    <mat-form-field class="w-100 mt-4">
        <input matInput autocomplete="off" type="details" [(ngModel)]="geofence.name"
            placeholder="{{ 'Settings.geofenceTab.name' | translate }}">
        <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !geofence.name ?
            'icon-cts-information pointer font-size-16 color-red-primary align-middle': 'align-middle'">
        </span>
    </mat-form-field>
    <mat-form-field class="w-100 mt-4">
        <input matInput autocomplete="off" [matAutocomplete]="auto" class="shapeAddress" type="details"
            [disabled]="isDialog" [(ngModel)]="geofence.address.address_formatted" [disabled]="geofence.type === 'path'"
            placeholder="{{ (geofence.type === 'path' && isDialog ? '' : 'Settings.geofenceTab.geofenceAddress') | translate }}">
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let suggestions of autocompleteAddressSuggestions.group[0].suggestions"
            [value]="suggestions.formatted_address" (onSelectionChange)="onClickAddressAutocomplete(suggestions)">
            <i *ngIf="suggestions.country_code" class="fi fi-{{ suggestions.country_code }}">
            </i>
            <span>{{ suggestions.formatted_address }}</span>
        </mat-option>
    </mat-autocomplete>
    <mat-form-field class="w-100 mt-4">
        <input matInput autocomplete="off" type="details" [(ngModel)]="latLngPosition" #latlngposition
            (keyup)="onChangePosition()" (paste)="onChangePosition()"
            placeholder="{{ 'Settings.geofenceTab.latLngLocation' | translate }}">
        <fa-icon [icon]="faCopy" (click)="manageLatLngPosition()" (click)="copyToClipboard()" class="mr-2"
            matTooltip="{{ 'Coordinates.copyToClipboard' | translate }}"
            [ngClass]="latLngPosition ? 'pointer color-blue' : 'color-grey'">
        </fa-icon>
        <i [ngClass]="{ 'color-red-primary': latLngPositionStatus === 0, 'color-blue': latLngPositionStatus === 1, 'color-green-primary pointer': latLngPositionStatus === 2 }"
            *ngIf="isDialog" (click)="manageLatLngPosition()" class="icon-geofence font-size-16"></i>
    </mat-form-field>
    <mat-form-field class="w-100 mt-4">
        <input matInput autocomplete="off" type="details" [disabled]="geofence.type === 'path'"
            [(ngModel)]="geofence.radius" (keyup)="onRadiusKeyup($event)"
            placeholder="{{ (geofence.type === 'path' ? '' : 'Settings.geofenceTab.geofenceRadius') | translate }}">
        <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'"
            [ngClass]="showMandatory && !geofence.radius ? 'icon-cts-information pointer font-size-16 color-red-primary align-middle': 'align-middle'" *ngIf="geofence.type === 'circle'">
        </span>
    </mat-form-field>
    <mat-form-field class="w-100 mt-4">
        <input matInput (change)="watchColorPicker($event)" autocomplete="off" type="color"
        [(ngModel)]="geofence.color" placeholder="{{ 'Settings.geofenceTab.color' | translate }}">
    </mat-form-field>
    <div class="ng-select-wrapper geofence flex-grow-1 mt-4">
        <ng-select placeholder="{{ 'Settings.geofenceTab.geofenceGroup' | translate }}" class="flex-grow-1"
            (search)="onDeviceSearch($event)" [closeOnSelect]="false" [multiple]="true"
            [(ngModel)]="geofence.geofenceGroups" [clearable]="true" [items]="geofenceGroups" bindLabel="name"
            bindValue="id" appendTo="body" (change)="geofenceGroupsOnChange($event)">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <ng-container *ngFor="let item of items; let i=index">
                    <div class="ng-value" *ngIf="i < 2">
                        <span class="ng-value-label">{{ item.name.slice(0,6) }}</span>
                        <span class="ml-1 ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </div>
                </ng-container>
                <div class="ng-value" *ngIf="items.length > 2">
                    <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                    <span class="ml-2">{{ item.name }}</span>
                </mat-checkbox>
            </ng-template>
            <ng-template ng-header-tmp>
                <div style="margin-right: 10px" *ngIf="showDeviceListSelectAll">
                    <mat-checkbox (click)="onSelectAllVehicles($event)" [checked]="isSelectAllVehiclesChecked">
                        <span class="ml-2 color-blue">{{ 'selectAll' | translate }}</span>
                    </mat-checkbox>
                </div>
            </ng-template>
        </ng-select>
    </div>
    <mat-form-field class="w-100 mt-4">
        <textarea matInput autocomplete="off" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="2"
            [(ngModel)]="geofence.description" placeholder="{{ 'Settings.geofenceTab.geofenceNotes' | translate }}">
        </textarea>
    </mat-form-field>
    <mat-spinner class="m-auto" *ngIf="isSpinnerDisplayed" [diameter]="100"></mat-spinner>
</div>
<div *ngIf="!isDialog" class="d-flex align-items-center justify-content-center mt-4 mb-4">
    <div class="button-component utility-green-bg-color" (click)="onSave()">
        <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
            <span class="color-white ml-0">{{ 'save' | translate }}</span>
        </div>
    </div>
</div>