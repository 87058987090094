
export const environment = {
    production: false,
    projectName: 'cargotrack',
    appTitle: 'CargoTrack',
    ctsLoginUrl: 'https://cts.cargotrack.ro/',
    isRandomBackgroundAtSignin: true,
    isGoogleMapsAvailable: true,
    googleapisVersion: '3.56',
    googleapisKey: 'AIzaSyCvpfD6NGFg2oV8nsGoBOM_4Q9UMtZgjMs',
    googleapisLibraries: 'geometry,drawing',
    masterDs: 'https://on-ts1.cargotrack.ro/',
};
