import { Component, OnInit, Output, Input, EventEmitter, AfterViewInit, OnDestroy, ElementRef, ViewEncapsulation } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { GoogleapisService } from '../../modules/service/shared/googleapis.service';
import { GeofenceService } from 'app/modules/service/map/geofence.service';

import { AutocompleteAddressSuggestions } from '../../modules/model/route/autocompleteaddresssuggestions.model';
import { GeoCoords } from '../../modules/model/map/geocoords.model';
import { Geofence } from '../../modules/model/map/geofence.model';

import { ConfirmActionComponent } from '../confirm-action/confirm-action.component';
import { NotificationComponent } from '../notification/notification.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const DEFAULT_RADIUS = 2000;
const LATLNG_INVALID = 0;
const LATLNG_UNCHANGED = 1;
const LATLNG_VALID = 2;

@Component({
    selector: 'app-add-edit-geofence',
    templateUrl: './add-edit-geofence.component.html',
    styleUrls: ['./add-edit-geofence.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddEditGeofenceComponent implements OnInit, AfterViewInit, OnDestroy {

    private shapeAddressSubscription: Subscription;
    private drawingManager: google.maps.drawing.DrawingManager;
    private polyOptions = { strokeWeight: 0, fillOpacity: 0.45, editable: true, draggable: true };

    public autocompleteAddressSuggestions: AutocompleteAddressSuggestions;
    public isSpinnerDisplayed: boolean;
    public geofenceTypes = ['circle', 'path'];
    public isSelectAllVehiclesChecked: boolean;
    public showMandatory: boolean;
    public latLngPosition: string;
    public latLngPositionStatus: number;
    public showDeviceListSelectAll: boolean;
    public faCopy = faCopy;

    @Output() cancelGeofenceEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() saveGeofenceEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() deleteGeofenceEventEmitter: EventEmitter<number> = new EventEmitter();
    @Input() geofence: Geofence;
    @Input() geofenceGroups: any[];
    @Input() map: any;
    @Input() isDialog = false;


    constructor(
        private googleapisService: GoogleapisService,
        private addressRef: ElementRef,
        private geofenceService: GeofenceService,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.showDeviceListSelectAll = true;
        this.latLngPosition = '';
        this.latLngPositionStatus = LATLNG_UNCHANGED;
        if (this.geofence.id) {
            this.latLngPosition = this.geofence.center_latitude + ', ' + this.geofence.center_longitude;
        }

        this.showMandatory = false;
        this.isSelectAllVehiclesChecked = false;
        this.autocompleteAddressSuggestions = new AutocompleteAddressSuggestions;
        this.autocompleteAddressSuggestions.group = [{ name: 'unknown', suggestions: [] }];

        this.geofenceService.dilogSaveGeofenceSubject.subscribe(() => this.onSave());
    }

    ngAfterViewInit(): void {
        if (this.isDialog) {
            return;
        }
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.CIRCLE, google.maps.drawing.OverlayType.POLYGON]
            },
            markerOptions: { draggable: true },
            polylineOptions: { editable: true, draggable: true },
            rectangleOptions: this.polyOptions,
            circleOptions: this.polyOptions,
            polygonOptions: this.polyOptions,
            map: this.map
        });

        google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event: google.maps.drawing.OverlayCompleteEvent) => {
            this.deleteShape();
            this.resetGeofence();
            this.geofence.shape = event.overlay;
            if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
                this.geofence.type = 'circle';
                this.geofence.radius = this.geofence.shape.radius;
                this.geofence.shape.addListener('radius_changed', () => this.onCreatedShapeRadiusChange(this.geofence.shape));
            } else {
                this.geofence.type = 'path';
            }
        });
        this.shapeAddressSetup();

        if (this.geofence.id) {

            if (this.geofence.shape) {
                this.geofence.shape.setVisible(true);
                this.geofence.shape.setDraggable(true);
                this.geofence.shape.setEditable(true);
            } else {
                switch (this.geofence.type) {
                    case 'circle':
                        this.createShapeCircle();
                        break;
                    case 'path':
                        this.createShapePlygon();
                        if (!this.geofence.center_latitude || !this.geofence.center_longitude) {
                            const polyCenter = this.geofenceService.getPolygonApproximateCenter(this.geofence.shape);
                            this.geofence.center_latitude = polyCenter.lat();
                            this.geofence.center_longitude = polyCenter.lng();
                        }
                        break;
                }
            }
        }
    }

    ngOnDestroy(): void {
        if (!this.isDialog) {
            this.deleteShape();
            this.drawingManager.setMap(null);
            this.drawingManager = null;
        }
    }

    public showErrorNotifications(): void {
        this.isSpinnerDisplayed = false;
        this.dialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: {
                    success: 0,
                    headermessage: this.geofenceService.getTranslationFor('error'),
                    message: this.geofenceService.getTranslationFor('errorNotification')
                }
            }
        );
    }

    private createShapeCircle(): void {
        this.geofence.shape = this.geofenceService.createGoogleMapsCircle(this.map, this.geofence, this.polyOptions, true);
        this.geofence.shape.addListener('radius_changed', () => this.onCreatedShapeRadiusChange(this.geofence.shape));
        this.fitToBounds();
    }

    private createShapePlygon(): void {
        this.geofence.shape = this.geofenceService.createGoogleMapsPolygon(this.map, this.geofence, true, true);
        this.fitToBounds();
    }

    private onCreatedShapeRadiusChange(shape: any): void {
        this.geofence.radius = shape.getRadius();
    }

    private onCreatedShapeDragend(): void {
        const coords = this.geofence.shape.getPath().getArray().map((latLng: any) => new GeoCoords(latLng.lng(), latLng.lat()));
        this.geofence.coords = JSON.stringify(coords);

        const polyCenter = this.geofenceService.getPolygonApproximateCenter(this.geofence.shape);
        this.geofence.center_latitude = polyCenter.lat();
        this.geofence.center_longitude = polyCenter.lng();
    }

    private resetGeofence(): void {
        this.geofence.center_latitude = null;
        this.geofence.center_longitude = null;
        this.latLngPosition = '';
        this.latLngPositionStatus = LATLNG_UNCHANGED;
        this.geofence.radius = null;
        this.geofence.coords = null;
        this.geofence.address = { address_formatted: '' };
    }

    private deleteShape(): void {
        if (this.geofence.shape) {
            this.geofence.shape.setVisible(false);
            this.geofence.shape.setDraggable(false);
            this.geofence.shape.setEditable(false);
        }
    }

    private findAddressbyLatlng(lat: string, lng: string): void {
        this.googleapisService.reverseGeocode(lat, lng).subscribe(
            response => {
                this.geofence.address = response;
                this.saveGeofenceEventEmitter.emit();
            },
            () => {
                this.saveGeofenceEventEmitter.emit();
                this.isSpinnerDisplayed = false;
            }
        );
    }

    private fitToBounds(): void {
        const bounds = new google.maps.LatLngBounds();
        if (this.geofence.type === 'circle') {
            bounds.extend(new google.maps.LatLng(Number(this.geofence.center_latitude), Number(this.geofence.center_longitude)));
            this.map.fitBounds(bounds);
            this.map.setZoom(10);
        } else {
            this.geofence.coords.forEach(
                (item: GeoCoords) => bounds.extend(new google.maps.LatLng(Number(item['lat']), Number(item['lng'])))
            );
            this.map.fitBounds(bounds);
        }
    }

    private shapeAddressSetup(): void {
        if (this.shapeAddressSubscription) {
            this.shapeAddressSubscription.unsubscribe();
        }
        const shapeAddress = this.addressRef.nativeElement.querySelectorAll('.shapeAddress');
        const shapeAddressObservableInput = fromEvent(shapeAddress, 'input')
            .pipe(map(event => event['target'].value)).pipe(debounceTime(500), distinctUntilChanged());

        this.shapeAddressSubscription = shapeAddressObservableInput.subscribe(
            value => {
                if (value.length < 3) {
                    return;
                }
                this.autocompleteAddressSuggestions.group = [{ name: 'unknown', suggestions: [] }];

                this.googleapisService.nominatimGeocode(value).subscribe(
                    (response) => {
                        const results = response.results.slice(0, 5);
                        results.forEach(element => {
                            if (element.length > 70) {
                                this.autocompleteAddressSuggestions.group[0].suggestions.push(element.substring(0, 68) + '...');
                            } else {
                                this.autocompleteAddressSuggestions.group[0].suggestions.push(element);
                            }
                        });
                    }
                );
            }
        );
    }

    public onCancel(): void {
        this.cancelGeofenceEventEmitter.emit();
    }

    public onSave(): void {
        if (
            (this.geofence.shape && this.geofence.name && !this.isDialog && !this.geofence.radius && this.geofence.type === 'path') ||
            (this.geofence.shape && this.geofence.name && !this.isDialog && this.geofence.radius && this.geofence.type === 'circle') ||
            (this.geofence.name && this.isDialog && !this.geofence.radius && this.geofence.type === 'path') ||
            (this.geofence.name && this.isDialog && this.geofence.radius && this.geofence.type === 'circle')
        ) {
            this.showMandatory = false;
            if (!this.isDialog) {
                this.isSpinnerDisplayed = true;
                switch (this.geofence.type) {
                    case 'circle':
                        this.geofence.center_latitude = this.geofence.shape.center.lat();
                        this.geofence.center_longitude = this.geofence.shape.center.lng();
                        break;
                    case 'path':
                        this.onCreatedShapeDragend();
                        break;
                }
            } else {
                if (this.geofence.type === 'path' && typeof this.geofence.coords !== 'string') {
                    this.geofence.coords = JSON.stringify(this.geofence.coords);
                }
            }
            this.findAddressbyLatlng(this.geofence.center_latitude.toString(), this.geofence.center_longitude.toString());
        } else {
            this.showMandatory = true;
        }
    }

    public onDelete(): void {
        const dialogRef = this.dialog.open(
            ConfirmActionComponent,
            {
                width: '450px', panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.geofenceService.getTranslationFor('delete'),
                    contentMessage: this.geofenceService.getTranslationFor('Settings.geofenceTab.geofenceDeleteMessage')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response) => {
                if (response) {
                    this.deleteGeofenceEventEmitter.emit();
                }
            }
        );
    }

    public onClickAddressAutocomplete(suggestions: any): void {
        this.geofence.center_latitude = suggestions.geometry.location.lat;
        this.geofence.center_longitude = suggestions.geometry.location.lng;
        this.latLngPosition = this.geofence.center_latitude + ',' + this.geofence.center_longitude;
        this.latLngPositionStatus = LATLNG_UNCHANGED;
        this.geofence.radius = DEFAULT_RADIUS;
        this.geofence.type = 'circle';
        this.autocompleteAddressSuggestions = new AutocompleteAddressSuggestions;
        this.autocompleteAddressSuggestions.group = [{ name: 'unknown', suggestions: [] }];
        this.deleteShape();
        this.createShapeCircle();
    }

    public geofenceTypeOnChange(event: any): void {
        if (!this.isDialog) {
            this.resetGeofence();
            this.deleteShape();
        }
        if(event == 'circle') {
            this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
        }
        else {
            this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
        }
    }

    public geofenceGroupsOnChange(event: any): void {
        this.isSelectAllVehiclesChecked = (this.geofence.geofenceGroups.length === this.geofenceGroups.length);
    }

    public preventEventToCheckbox(event): void {
        event.preventDefault();
    }

    public onSelectAllVehicles(event): void {
        event.preventDefault();
        this.isSelectAllVehiclesChecked = !this.isSelectAllVehiclesChecked;
        this.geofence.geofenceGroups = this.isSelectAllVehiclesChecked ? this.geofenceGroups.map((groups: any) => groups.id) : [];
    }

    public onRadiusKeyup(event: any): void {
        if (isNaN(this.geofence.radius)) {
            this.geofence.radius = DEFAULT_RADIUS;
        }
        if (this.geofence.shape) {
            this.geofence.shape.setRadius(Number(this.geofence.radius));
        }
    }

    public onChangePosition(): void {
        const latLngRegExp = new RegExp(/^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/);
        if (latLngRegExp.test(this.latLngPosition)) {
            this.latLngPositionStatus = LATLNG_VALID;
            this.manageLatLngPosition();
        } else {
            this.latLngPositionStatus = LATLNG_INVALID;
        }

    }

    public manageLatLngPosition(): void {
        if (this.latLngPositionStatus === LATLNG_VALID) {
            const latLng = this.latLngPosition.split(',');
            this.isSpinnerDisplayed = true;
            this.googleapisService.reverseGeocode(latLng[0].trim(), latLng[1].trim()).subscribe(
                (address: string) => {
                    this.geofence.address = address;
                    if (!this.geofence.radius) {
                        this.geofence.radius = DEFAULT_RADIUS;
                    }
                    this.geofence.type = 'circle';
                    this.latLngPositionStatus = LATLNG_UNCHANGED;
                    this.geofence.center_latitude = Number(latLng[0].trim());
                    this.geofence.center_longitude = Number(latLng[1].trim());
                    this.deleteShape();
                    this.createShapeCircle();
                    this.isSpinnerDisplayed = false;
                },
                () => this.showErrorNotifications()
            );
        }
    }
    
    public copyToClipboard(): void {
        if (this.latLngPosition) {
            const selBox = document.createElement('textarea');
            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            selBox.value = this.latLngPosition;
            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand('copy');
            document.body.removeChild(selBox);
        }
    }

    public onDeviceSearch(event: any): void {
        this.showDeviceListSelectAll = event.term.length > 0 ? false : true;
    }

    public watchColorPicker(event) : void {
        this.geofence.color = event.target.value;
    }
}
