import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import * as moment from 'moment';


import { EuplatescpaymentComponent } from 'app/shared/euplatescpayment/euplatescpayment.component';
import { RecurentPaymentComponent } from 'app/shared/recurentpayment/recurentpayment.component';
import { Invoice } from 'app/modules/model/accounting/invoice.model';
import { AccountingService } from 'app/modules/service/shared/accounting.service';
import { NotificationComponent } from '../notification/notification.component';
import { StatusCodes } from 'http-status-codes';
import { HttpErrorResponse } from '@angular/common/http';
import { SelectCardIssuerComponent } from '../select-card-issuer/select-card-issuer.component';
@Component({
    selector: 'app-invoicelist',
    templateUrl: './invoicelist.component.html',
    styleUrls: ['./invoicelist.component.scss']
})
export class InvoicelistComponent implements OnInit, OnDestroy {
    public totalSelected = 0;
    public displayedColumns = [
        'select', 'serial', 'company', 'date', 'due_date', 'paid_date', 'totalvalue', 'paid_amount', 'payment-difference', 'actions'
    ];
    public dataSource: MatTableDataSource<Invoice>;
    public selection: Invoice[] = [];
    public selectionCompanyId: number;
    public invoices: Invoice[] = [];
    public recurencyInfo: any[] = [];
    public companyWithRecurency: any[] = [];
    private getInvoicesTimer;
    public masspayment = false;
    public filter = 'allInvoices';
    public spinnerVisible: boolean;

    @Input() custom1: number;
    @Input() set setFilter(filter: string) {
        this.filterBy(filter);
    }

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private accountService: AccountingService,
        private readonly translateService: TranslateService,
        public dialog: MatDialog,
        private domSanitizer: DomSanitizer
    ) {
        this.translateService.setDefaultLang('ro_RO');
        const lang = localStorage.getItem('currentLang');
        if (lang) {
            this.translateService.use(lang);
        }
    }

    ngOnInit() {
        this.accountService.recurencyStatus(this.custom1);
        this.accountService.recurencyInfoSubject.subscribe(
            (data: any) => {
                this.companyWithRecurency = Object.values(data).map(function(element: any){
                    let companyInfo = element.company_id
                    return companyInfo;
                });
            }
        );
        this.accountService.invoiceList$.subscribe(
            (data) => {
                if (!data) {
                    return;
                }
                let hasInProgress = false;
                this.invoices = data;
                this.filterBy(this.filter);
                data.forEach(invoice => {
                    if (invoice.payment_status === 1) {
                        hasInProgress = true;
                    }
                });

                if (hasInProgress && !this.getInvoicesTimer) {
                    this.getInvoicesTimer = setTimeout(
                        () => { this.accountService.getInvoices(this.custom1); }
                    , 60000);
                }
            },
            () => this.showErrorNotification()
        );

        this.accountService.payAllSubject.subscribe( (type: number) => this.payAll(type));
    }

    ngOnDestroy(): void {
        if (this.getInvoicesTimer) {
            clearTimeout(this.getInvoicesTimer);
        }
    }

    private showErrorNotification(): void {
        this.spinnerVisible = false;
        this.dialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: {
                    success: 0,
                    headermessage: this.accountService.getTranslationFor('error'),
                    message: this.accountService.getTranslationFor('generalError')
                }
            }
        );
    }

    public isDateDue(dateDue: string): boolean {
        return (moment().diff(dateDue, 'days') > 0);
    }

    public applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    public downloadInvoice(invoiceId: number) {
        this.spinnerVisible = true;
        this.accountService.downloadInvoice(invoiceId).subscribe(
            (response: any) => {
                if (response.data.length !== undefined) {
                    const filename = response.filename;
                    const content = response.data;
                    const filetype = '';
                    const byteCharacters = content;
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: filetype });
                    this.spinnerVisible = false;
                    saveAs(blob, filename);
                }
            },
            () => this.showErrorNotification()
        );
    }

    public payInvoice(invoice: any, card: number) {
        if (card) {
            this.payInvoiceExecution(invoice, card);
        } else {
            const issuerRef = this.dialog.open(
                SelectCardIssuerComponent,
                {
                    width: '450px',
                    disableClose: true,
                    panelClass: ['custom-dialog-container']
                }
            );
    
            issuerRef.afterClosed().subscribe((issuingBank) => {
                if(issuingBank) {
                    const selectedCard = issuingBank === 'transilvaniaBank' ? AccountingService.TYPE_PAYMENT_BT : AccountingService.TYPE_PAYMENT_EUPLATESC_REGULAR;
                    this.payInvoiceExecution(invoice, selectedCard);
                }
            })
        }
    }

    private payInvoiceExecution(invoice: any, cardIssuer: number): void {
        this.spinnerVisible = true;
        this.toggle(invoice);
        if (this.selection.length === 1 && cardIssuer === AccountingService.TYPE_PAYMENT_EUPLATESC_REGULAR) {
            if(this.companyWithRecurency.indexOf(invoice.company_id) > -1) {
                this.initInvoicePayment(invoice, cardIssuer);
            } else {
                const drRef = this.dialog.open(
                    RecurentPaymentComponent,
                    {
                        width: '450px',
                        disableClose: true,
                        panelClass: ['custom-dialog-container']
                    }
                );
                drRef.afterClosed().subscribe((result) => {
                    if (result) {
                        if(result.recurent) {
                            let recurencyInfo = {
                                max_amount: result.maxAmount,
                                expiration_date: result.expirationDate,
                                invoiceIds: [invoice.id],
                                card_issuer: cardIssuer
                            };
                            this.spinnerVisible = true;
                            this.accountService.activateRecurencyAndRegeneratePaymentUrl(recurencyInfo).subscribe({
                                next:(response) => {
                                    invoice.payment_url = response.payment_url;
                                    this.initInvoicePayment(invoice, cardIssuer);
                                },
                                error:(() => {
                                    this.dialog.open(
                                        NotificationComponent,
                                        {
                                            maxWidth: '520px',
                                            minWidth: '300px',
                                            panelClass: 'custom-dialog-container',
                                            data: {
                                                success: 0,
                                                headermessage: this.accountService.getTranslationFor('error'),
                                                message: this.translateService.instant('generalError')
                                            }
                                        }
                                        );
                                    })
                            })
                            this.spinnerVisible = false;
                        } else {
                            this.initInvoicePayment(invoice, cardIssuer);
                        }
                    }
                });
            }
        } else {
            this.payAllChecked(cardIssuer);
        }
        this.spinnerVisible = false;
    }

    private initInvoicePayment(invoice: any, cardIssuer: number): void {
        if (invoice.payment_url && cardIssuer === AccountingService.TYPE_PAYMENT_EUPLATESC_REGULAR) {
            this.spinnerVisible = true;
            this.accountService.statusInvoice(invoice.id, invoice.payment_url).subscribe({
                next:() => {
                    this.spinnerVisible = false;
                    this.processEuPlatescPayment(invoice.payment_url)
                },
                error:((error: HttpErrorResponse) => {
                    this.spinnerVisible = false;
                    let message = null;
                    switch (error.status) {
                        case StatusCodes.BAD_REQUEST:
                                message = this.accountService.getTranslationFor('Subscriptions.overview.invoiceNotFound')
                            break;
                        case  StatusCodes.FAILED_DEPENDENCY:
                            message = this.accountService.getTranslationFor('Subscriptions.invoiceAlreadyPaid')
                            break;
                        case StatusCodes.UNPROCESSABLE_ENTITY:
                            message = this.accountService.getTranslationFor('Subscriptions.invoiceNotBeSetProgress')
                            break;
                    }
                    if(message) {
                        this.dialog.open(
                            NotificationComponent,
                            {
                                maxWidth: '520px',
                                minWidth: '300px',
                                panelClass: 'custom-dialog-container',
                                data: {
                                    success: 0,
                                    headermessage: this.accountService.getTranslationFor('error'),
                                    message: message
                                }
                            }
                        );
                    }
                }
            )})
        } else {
            this.payAllCheckedInvoices(cardIssuer);
        }
    }

    public payAllChecked(cardIssuer: number) {
        if (cardIssuer) {
            this.payAllCheckedExecute(cardIssuer);
        } else {
            const issuerRef = this.dialog.open(
                SelectCardIssuerComponent,
                {
                    width: '450px',
                    disableClose: true,
                    panelClass: ['custom-dialog-container']
                }
            );
    
            issuerRef.afterClosed().subscribe((issuingBank) => {
                if(issuingBank) {
                    const cardSelected = issuingBank === 'transilvaniaBank' ? AccountingService.TYPE_PAYMENT_BT : AccountingService.TYPE_PAYMENT_EUPLATESC_REGULAR;
                    this.payAllCheckedExecute(cardSelected);
                }
            })
        }
    }

    private payAllCheckedExecute(cardIssuer: number):void {
        if(this.companyWithRecurency.indexOf(this.selectionCompanyId) > -1 || cardIssuer === AccountingService.TYPE_PAYMENT_BT) {
            this.payAllCheckedInvoices(cardIssuer);
        } else {
            const drRef = this.dialog.open(
                RecurentPaymentComponent, { disableClose: true, panelClass: ['overflow-y-visible', 'custom-dialog-container', 'bg-prepare-payment-dialog'] }
            );

            drRef.afterClosed().subscribe((result) => {
                if(result !== false && result.recurent) {
                    let recurencyInfo = {
                        max_amount: result.maxAmount,
                        expiration_date: result.expirationDate,
                        invoiceIds: this.selection.map((inv) => inv.id),
                        card_issuer: cardIssuer
                    };
                    this.spinnerVisible = true;
                    this.accountService.activateRecurencyAndRegeneratePaymentUrl(recurencyInfo).subscribe({
                        next:(response) => {
                            this.spinnerVisible = false;
                            if (response.payment_url) {
                                this.processEuPlatescPayment(response.payment_url);
                            } else {
                                alert(this.getTranslationFor('Accounting.invoice.error'));
                            }
                        },
                        error:(() => {
                            this.dialog.open(
                                NotificationComponent,
                                {
                                    maxWidth: '520px',
                                    minWidth: '300px',
                                    panelClass: 'custom-dialog-container',
                                    data: {
                                        success: 0,
                                        headermessage: this.accountService.getTranslationFor('error'),
                                        message: this.translateService.instant('generalError')
                                    }
                                }
                            );
                        })
                    })
                    this.spinnerVisible = false;
                } else {
                    this.payAllCheckedInvoices(cardIssuer);
                }
            });
        }
    }

    private payAllCheckedInvoices(cardIssuer: number) {
        this.accountService.registerPaymentInvoice(this.selection, cardIssuer).subscribe({
            next:(response: { payment_url: string }) => {
                this.spinnerVisible = false;
                if (response.payment_url) {
                    this.processEuPlatescPayment(response.payment_url);
                }
            },
            error:() => {
                this.spinnerVisible = false;
                this.dialog.open(
                    NotificationComponent,
                    {
                        maxWidth: '800px', panelClass: 'custom-dialog-container',
                        data: { success: 0, headermessage: this.getTranslationFor('error'), message: this.getTranslationFor('Accounting.invoice.error') }
                    }
                );
            }
        });
    }

    private processEuPlatescPayment(payment_url: string): void {
        const dRef = this.dialog.open(
            EuplatescpaymentComponent,
            {
                width: '832px',
                disableClose: true,
                panelClass: ['custom-dialog-container', 'full-height-class'],
                data: {
                    iframeSrc: this.domSanitizer.bypassSecurityTrustResourceUrl(payment_url),
                    headermessage: this.accountService.getTranslationFor('Accounting.payment.ayment'),
                    contentMessage: this.accountService.getTranslationFor('Accounting.payment.exitconfirm_text') + '\n' +
                    this.accountService.getTranslationFor('Accounting.payment.exitconfirm'),
                    buttonnumber: 1
                }
            }
        );
        dRef.afterClosed().subscribe(() => {
            this.selection = [];
            this.accountService.getInvoices(this.custom1);
        });
    }

    public isSelected(row: Invoice) {
        const element = this.selection.find((elem: Invoice) => elem.id === row.id);
        return (element ? true : false);
    }

    public toggle(row) {
        this.masspayment = true;
        this.totalSelected = 0;
        if (this.isSelected(row)) {
            this.selection = this.selection.filter(elem => elem.company.id === row.company.id);
            const array = this.dataSource.data.filter(elem =>
                moment(elem.date).isSameOrAfter(row.date) &&
                moment(elem.created_at).isSameOrAfter(row.created_at) &&
                [0, 3, 4].indexOf(elem.payment_status) > -1 &&
                elem.company.id === row.company.id
            );
            array.forEach(item => {
                if (this.isSelected(item)) {
                    const index: number = this.selection.indexOf(item);
                    this.selection.splice(index, 1);
                }
            });
        } else {
            this.selection = this.selection.filter(elem => elem.company.id === row.company.id);
            const array = this.dataSource.data.filter(elem => moment(elem.date).isSameOrBefore(row.date)
                && moment(elem.created_at).isSameOrBefore(row.created_at) &&
                [0, 3, 4].indexOf(elem.payment_status) > -1 &&
                elem.company.id === row.company.id);
            array.forEach(item => {
                if (!this.isSelected(item) && [0, 3, 4].indexOf(item.payment_status) > -1) {
                    this.selection.push(item);
                }
            });
            this.selectionCompanyId = row.company.id
        }
        this.totalSelected = this.selection.reduce((sum, current) => sum + (+current.total - +current.paid_amount), 0);
    }

    public isAllSelected() {
        const numSelected = this.selection.length;
        const numRows = this.dataSource.data.filter(elem => [0, 3, 4].indexOf(elem.payment_status) > -1).length;
        return numSelected === numRows;
    }

    public getTranslationFor(label: string): String {
        return this.accountService.getTranslationFor(label);
    }

    public filterBy(filter: string): void {

        this.selection = [];
        this.masspayment = false;
        this.totalSelected = 0;

        this.filter = filter;
        switch (filter) {
            case 'allInvoices':
                this.dataSource = new MatTableDataSource(this.invoices);
                break;
            case 'paidInvoices':
                this.dataSource = new MatTableDataSource(this.invoices.filter( elem => elem.payment_status === 2));
                break;
            case 'dueInvoices':
                this.dataSource = new MatTableDataSource(this.invoices.filter(
                    elem => elem.payment_status !== 2 && elem.payment_status !== 1 && this.isDateDue(elem['date_due'])));
                break;
        }
        this.dataSource.paginator = this.paginator;
    }

    public payAll(type: number): void {
        this.filter = 'allInvoices';
        this.dataSource = new MatTableDataSource(this.invoices);
        this.dataSource.paginator = this.paginator;
        this.selection = [];
        switch (type) {
            case 1:
                this.selection = this.dataSource.data.filter(
                    elem => elem.payment_status !== 2 && elem.payment_status !== 1);
                break;
            case 2:
                this.selection = this.dataSource.data.filter(
                    elem => elem.payment_status !== 2 && elem.payment_status !== 1 && this.isDateDue(elem['date_due']));
                break;
        }

        const length = this.selection.length;
        if (length > 0) {
            this.selection = this.selection.filter(el => el.company.id === this.selection[length - 1].company.id);
        }
        this.masspayment = this.selection.length > 0;
        this.totalSelected = this.selection.reduce((sum, current) => sum + (+current.total - +current.paid_amount), 0);
    }
}
