<h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18">
    {{ 'Rer.objects.addClient' | translate }}
</h4>
<div mat-dialog-content class="content pl-4 pr-4">
    <div class="d-flex mt-3 max-width-724px">
        <mat-form-field class="w-100 mr-4">
            <input type="details" matInput type="details"
                placeholder="{{ 'Rer.clients.addEdit.overview.name' | translate }}" [(ngModel)]="data.name">
            <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !data.name ?
                'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
            </span>
        </mat-form-field>
        <mat-form-field class="w-100">
            <input type="details" matInput type="details"
                placeholder="{{ 'Rer.clients.addEdit.overview.code' | translate }}" [(ngModel)]="data.code">
            <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !data.code ?
                'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
            </span>
        </mat-form-field>
    </div>
    <div class="d-flex mt-3 max-width-724px">
        <mat-form-field class="w-100 mr-4">
            <input type="details" matInput type="details"
                placeholder="{{ 'Rer.clients.addEdit.overview.phone' | translate }}" [(ngModel)]="data.phone">
            <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !data.phone ?
                'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
            </span>
        </mat-form-field>
        <mat-form-field class="w-100">
            <input type="details" matInput type="details"
                placeholder="{{ 'Rer.clients.addEdit.overview.contactPerson' | translate }}"
                [(ngModel)]="data.delegate_name">
            <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !data.delegate_name ?
                'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
            </span>
        </mat-form-field>
    </div>
    <div class="mt-3 max-width-724px">
        <mat-form-field class="w-100">
            <textarea type="details" matInput placeholder="{{ 'Rer.clients.addEdit.overview.description' | translate }}"
                [(ngModel)]='data.description' [ngModelOptions]="{standalone: true}">
            </textarea>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions class="mt-4 p-2">
    <div class="buttons d-flex align-items-center justify-content-between pt-2">
        <div class="button-component bgcolor-white" (click)="dialogRef.close(false)">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="button-component bgcolor-blue" (click)="onAdd()">
            <div class="inner inner-blue user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'add' | translate }}</span>
            </div>
        </div>
    </div>
</div>