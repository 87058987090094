import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from '../modules/map/interface/duration.interface';
import * as moment from 'moment';
import 'moment-duration-format';

@Pipe({
    name: 'timeduration'
})
export class TimeDuration implements PipeTransform {

    transform(value: string) {

        if (!value || value.length === 0) {
            return '0m';
        }
        const duration = moment.duration(value, 'seconds') as Duration;

        return duration.format('d[d] h[h] m[m] s[s]');
    }
}
