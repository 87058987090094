import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringfilter'
})
export class StringfilterPipe implements PipeTransform {

    transform(value: any, filterString: string, properties: string[]): any {

        if (!filterString) {
            return value;
        }
        const resultArray = [];
        for (const item of value) {
            for (const prop of properties) {
                if (!item.hasOwnProperty(prop)) {
                    if (prop.includes('.')) {
                        let propObject, property;
                        [propObject, property] = prop.split('.');
                        if (
                            (
                                typeof item[propObject][property] === 'number' &&
                                item[propObject][property] === parseInt(filterString, 10)
                            )
                            ||
                            (
                                typeof item[propObject][property] === 'string' &&
                                item[propObject][property].toLowerCase().indexOf(filterString.toLowerCase()) !== -1
                            )
                        ) {
                            resultArray.push(item);
                            break;
                        }

                    }
                    continue;
                }
                if (
                    (typeof item[prop] === 'number' && item[prop] === parseInt(filterString, 10)) ||
                    (typeof item[prop] === 'string' && item[prop].toLowerCase().indexOf(filterString.toLowerCase()) !== -1)
                ) {
                    resultArray.push(item);
                    break;
                }
            }
        }

        return resultArray;
    }

}
