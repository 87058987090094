import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-custom-time-interval',
    templateUrl: './custom-time-interval.component.html',
    styleUrls: ['./custom-time-interval.component.scss']
})
export class CustomTimeIntervalComponent implements OnInit {

    private startdate: string;
    private enddate: string;

    public optionsPredefined: any[];

    @Input() predefined = 1;
    @Input() disabled: boolean;
    @Output() selectTimeIntervarEventEmitter = new EventEmitter<{ startdate: string, enddate: string, predefined: number }>();

    constructor(private translateService: TranslateService) { }

    ngOnInit() {
        this.translatePredefineValues();
    }

    private translatePredefineValues(): void {

        this.optionsPredefined = [
            { value: 1, name: 'today', label: 'Map.vehicleHistory.today'},
            { value: 2, name: 'yesterday', label: 'Map.vehicleHistory.yesterday'},
            { value: 3, name: 'currentWeek', label: 'Map.vehicleHistory.currentWeek'},
            { value: 4, name: 'last7Days', label: 'Map.vehicleHistory.last7Days'},
            { value: 5, name: 'currentMonth', label: 'Map.vehicleHistory.currentMonth'},
            { value: 6, name: 'last30Days', label: 'Map.vehicleHistory.last30Days'},
            { value: 7, name: 'previousMonth', label: 'Map.vehicleHistory.previousMonth'},
            { value: 8, name: 'custom', label: 'Map.vehicleHistory.custom'},
        ];

        this.optionsPredefined.forEach(option =>
            this.translateService.get(option.label, {}).subscribe((response: string) => option.label = response)
        );
    }

    private setToday(): void {
        this.startdate = moment().startOf('day').format('YYYY-MM-DD HH:mm');
        this.enddate = moment().endOf('day').format('YYYY-MM-DD HH:mm');
    }

    private setYesterday(): void {
        this.startdate = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
        this.enddate = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm');
    }

    private setCurrentWeek(): void {
        this.startdate = moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm');
        this.enddate = moment().endOf('day').format('YYYY-MM-DD HH:mm');
    }

    private setLast7Days(): void {
        this.startdate = moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
        this.enddate = moment().endOf('day').format('YYYY-MM-DD HH:mm');
    }

    private setCurrentMonth(): void {
        this.startdate = moment().date(1).startOf('day').format('YYYY-MM-DD HH:mm');
        this.enddate = moment().endOf('day').format('YYYY-MM-DD HH:mm');
    }

    private setLast30Days(): void {
        this.startdate = moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
        this.enddate = moment().endOf('day').format('YYYY-MM-DD HH:mm');
    }

    private setPreviousMonth(): void {
        this.startdate = moment().subtract(1, 'months').date(1).startOf('day').format('YYYY-MM-DD HH:mm');
        this.enddate = moment().date(0).endOf('day').format('YYYY-MM-DD HH:mm');
    }

    public onChangePredefined(opt: number): void {
        switch (opt) {
            case 1:
                this.setToday();
                break;
            case 2:
                this.setYesterday();
                break;
            case 3:
                this.setCurrentWeek();
                break;
            case 4:
                this.setLast7Days();
                break;
            case 5:
                this.setCurrentMonth();
                break;
            case 6:
                this.setLast30Days();
                break;
            case 7:
                this.setPreviousMonth();
                break;
        }

        this.selectTimeIntervarEventEmitter.emit({ startdate: this.startdate, enddate:  this.enddate, predefined: this.predefined });
    }
}
