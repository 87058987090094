<div class="row ml-0 mr-0 pt-2" [ngClass]="(submodule=='dashboard')?'mb-1':'mb-4'">
    <div class="col-md-12 ml-0">
        <div class="datatTableFilter p-2 d-flex" [ngClass]="(submodule=='dashboard')?'mb-1':'mb-4'" *ngIf="submodule != 'dashboard'">
            <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
            <input
                matInput
                (keyup)="applyFilter($event.target.value)"
                placeholder="{{ 'quicksearch' | translate }}">
        </div>
        <mat-table #table [dataSource]="dataSource" matSort [ngClass]="(submodule=='dashboard')?'review-list':'vehicle-list'" >
            <ng-container *ngIf="submodule=='client' || submodule=='dashboard'" matColumnDef="transporter">
                <mat-header-cell  *matHeaderCellDef mat-sort-header> {{ 'PGS.reviewList.transporterName' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.pgs_transporter.name }} </mat-cell>
            </ng-container>
            <ng-container *ngIf="(submodule=='driver' || submodule=='transporter' || submodule == 'vehicle' || submodule == 'dashboard') && (type=='sent' || type=='received' || type=='received_client' || type=='received_transporter')" matColumnDef="client">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PGS.reviewList.clientName' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.pgs_client.name }} </mat-cell>
            </ng-container>
            <ng-container *ngIf="(submodule=='driver' || submodule=='transporter' || submodule=='dashboard') && (type=='sent_loading_team' || type=='received_loading_team')" matColumnDef="loading_team">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PGS.reviewList.loadingTeam' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.loading_team_code }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="rating">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PGS.reviewList.rating' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"><div class="stars-outer"><div [ngStyle]="{'width': calculateStarsWidth(element.rating)+'%'}" class="stars-inner"></div></div></mat-cell>
            </ng-container>
            <ng-container *ngIf="submodule!='dashboard'" matColumnDef="comment">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PGS.reviewList.comment' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.comment }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PGS.createdAt' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.created_at | offset:true | date:dateFormat + ' HH:mm' }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="updated_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PGS.updatedAt' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.updated_at | offset:true | date:dateFormat + ' HH:mm' }} </mat-cell>
            </ng-container>
            <mat-header-row [ngClass]="{'d-none': submodule === 'dashboard'}"
                *matHeaderRowDef="displayedColumns"
                class="pt-2 pb-2 mb-1">
            </mat-header-row>
            <mat-row
                *matRowDef="let row; columns: displayedColumns;"
                class="mb-1 p-1 mr-3 pointer"
                (click)="openReviewDetails(row)">
            </mat-row>
        </mat-table>
        <mat-paginator
            #paginator
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 20, 50, 100]"
            [showFirstLastButtons]="true">
        </mat-paginator>
    </div>
</div>
