import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

import { Route as MapRoute } from '../../modules/model/route/route.model';
import { RouteService } from '../../modules/service/route/route.service';
import { RouteInterface } from '../../modules/tms/shipping/interface/route.interface';

@Component({
    selector: 'app-add-route-dialog',
    templateUrl: './add-route-dialog.component.html',
    styleUrls: ['./add-route-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddRouteDialogComponent {
    public errorMessage: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<AddRouteDialogComponent>,
        private routeService: RouteService,
        @Inject(MAT_DIALOG_DATA) public data: MapRoute
    ) {
        this.data.visible_from = this.routeService.setDateOffset(this.data.visible_from, false);
        this.data.visible_to = this.routeService.setDateOffset(this.data.visible_to, false);
    }

    public onSelectVisibleDate(event: { dateBegin: string, dateEnd: string }): void {

        this.data.visible_from = event.dateBegin;
        this.data.visible_to = event.dateEnd;
    }

    public getLocale(): String {

        return this.routeService.getLocale();
    }

    public onSubmit(): void {
        const routeNullDates = this.data.routes.filter(routeItem => (routeItem.datetime_begin || routeItem.datetime_end));
        this.errorMessage = [];
        if (routeNullDates.length > 0 && this.routeService.checkIfAfterDates(this.data.routes)) {
            this.errorMessage.push(this.routeService.getTranslationFor('Route.addRouteDialog.invalidDates'));
        }

        if (this.errorMessage.length === 0) {
            this.data.visible_from = this.routeService.setDateOffset(this.data.visible_from, true);
            this.data.visible_to = this.routeService.setDateOffset(this.data.visible_to, true);
            this.data.routes.forEach((route: RouteInterface) => route.path = JSON.stringify(route.path));
            this.routeService.createRoute(this.data).subscribe(
                (response) => {
                    if (response['type'] === 'success') {
                        this.dialogRef.close({
                            visibleFrom: this.data.visible_from,
                            visibleTo: this.data.visible_to,
                        });
                        if (this.data.id) {
                            this.routeService.routeEditedSubject.next(this.data);
                        }
                    } else if (response['type'] === 'error') {
                        this.errorMessage.push(this.routeService.getTranslationFor('Route.addRouteDialog.' + response['message']));
                    }
                },
                () => this.errorMessage.push(this.routeService.getTranslationFor('Route.addRouteDialog.unprocessableEntity'))
            );
        }

    }
}
