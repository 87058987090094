import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { ReviewDialogComponent } from '../dialogs/review-dialog/review-dialog.component';

@Component({
    selector: 'app-review-list',
    templateUrl: './review-list.component.html',
    styleUrls: ['./review-list.component.scss']
})
export class ReviewListComponent implements OnInit,OnChanges {
    public displayedColumns: string[];
    public dataSource: MatTableDataSource<any>;

    @Input() dateFormat: string;
    @Input() type: string;   
    @Input() reviews: any;
    @Input() submodule: string;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public actionDialog: MatDialog) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.setDataTable();
        this.setDataTablePaginatorSort();
    }
    
    ngOnInit() {
        this.setDataTable();
    }

    ngAfterViewInit() {
        this.setDataTablePaginatorSort();
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    private setDataTable(): void {
        this.displayedColumns = [
            'transporter',
            'client',
            'loading_team',
            'rating',
            'comment',
            'created_at',
            'updated_at'
        ];

        if(this.submodule == 'client') {
            this.displayedColumns = this.displayedColumns.filter(x => x != 'client');
            this.displayedColumns = this.displayedColumns.filter(x => x != 'loading_team');
        }

        if(this.submodule == 'driver' || this.submodule == 'transporter' || this.submodule == 'vehicle') {
            this.displayedColumns = this.displayedColumns.filter(x => x != 'transporter');
            if(this.type == 'sent_loading_team') {
                this.displayedColumns = this.displayedColumns.filter(x => x != 'client');
            } else {
                this.displayedColumns = this.displayedColumns.filter(x => x != 'loading_team');
            }
        }

        if(this.submodule == 'dashboard') {
            if(this.type == 'received_client' || this.type == 'received_transporter'){
                this.displayedColumns = this.displayedColumns.filter(x => x != 'loading_team');
            }
            if(this.type == 'received_loading_team') {
                this.displayedColumns = this.displayedColumns.filter(x => x != 'client');
            }
            this.displayedColumns = this.displayedColumns.filter(x => x != 'comment');
            this.displayedColumns = this.displayedColumns.filter(x => x != 'created_at');
        }

        this.dataSource = new MatTableDataSource(this.reviews);
    }

    public openReviewDetails(row: any) {
        this.actionDialog.open(
            ReviewDialogComponent,
            {
                panelClass: 'custom-dialog-container',
                width: '600px',
                data: { review: row, type: this.type, submodule: this.submodule, dateFormat: this.dateFormat },
                disableClose: false
            }
        );
    }

    public calculateStarsWidth(rating: number) {
        return rating / 5 * 100;
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }
}

