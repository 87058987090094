<mat-form-field style="width: 100%">
    <input type="text" autocomplete="off" name="number" ngModel #numberInput [(ngModel)]="phonenumber.tel"
        appPhoneNumberOnly maxlength="10" matInput (ngModelChange)="onNumberPress(numberInput.value)"
        placeholder="{{ 'phonenumber' | translate }}">
    <span matPrefix>
        <div class="dropdown">
            <button class="dropbtn" (click)="searchInput.value=''; onDropDownClick()">
                <i class="dropbtn-part fi fi-{{ selectedFlag.code | lowercase }} align-middle"></i>
                ({{ selectedFlag.dial_code }} )
                <span class="arrow-down"></span>
            </button>

            <div #flagDropdown class="dropdown-content">
                <input type="text" autocomplete="off" class="search-form-control" name="search" ngModel #searchInput
                    (ngModelChange)="search(searchInput.value)" placeholder="{{ 'phonenumber' | translate }}">
                <span *ngFor="let f of allowed" (click)=" onFlageSelect(f)">
                    <i class="fi fi-{{ f.code | lowercase }} align-middle"></i>
                    {{ f.name }}
                </span>
            </div>
        </div>
    </span>
</mat-form-field>