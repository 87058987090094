import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'unitmeasurement'
})
export class UnitMeasurementPipe implements PipeTransform {

    transform(value: any, umType: string, category: string, digits = 0) {
        if (value === 'undefined' || value === null || value === '-/-') {
            return value;
        }

        switch (umType) {
            case 'metric':
                return this.processMetricUm(value, category, digits);
            case 'us':
                return this.processUsUm(value, category, digits);
            default:
                return value;
        }

    }

    private processMetricUm(value: any, category: string, digits: number): string {
        let um: string;
        switch (category) {
            case 'distance':
                value = (+value).toFixed(digits);
                um = 'km';
                break;
            case 'distance_si':
                um = 'm';
                value = (+value * 1000).toFixed(digits);
                break;
            case 'volume':
                value = (+value).toFixed(digits);
                um = 'L';
                break;
            case 'speed':
                value = (+value).toFixed(digits);
                um = 'km/h';
                break;
            case 'consumption':
                value = (+value).toFixed(digits);
                um = '%';
                break;
            case 'consumptionH':
                value = (+value).toFixed(digits);
                um = 'L/h';
                break;
            case 'temperature':
                value = (+value).toFixed(digits);
                um = '°C';
                break;
            case 'avg_consumption':
                value = (+value).toFixed(digits);
                um = 'L/100km';
                break;
            case 'weight': 
                value = (+value).toFixed(digits);
                um = "kg";
                break;
            default:
                um = '';
                break;
        }

        return (value !== undefined) ? value + ' ' + um : um;
    }

    private processUsUm(value: any, category: string, digits: number): string {
        let um: string;
        switch (category) {
            case 'distance':
                um = 'mi';
                value = (+value * 0.62137).toFixed(digits);
                break;
            case 'distance_si':
                um = 'mi';
                value = (+value * 0.62137).toFixed(digits);
                break;
            case 'volume':
                value = (+value * 0.264172052).toFixed(digits);
                um = 'gal';
                break;
            case 'speed':
                um = 'mph';
                value = (+value * 0.62137).toFixed(digits);
                break;
            case 'consumption':
                um = 'mpg';
                value = isNaN(+value) ? '' : (235.215 / +value).toFixed(digits);
                break;
            case 'consumptionH':
                um = 'gal/h';
                value = isNaN(+value) ? '' : (0.26 / +value).toFixed(digits);
                break;
            case 'temperature':
                um = '°F';
                value = isNaN(+value) ? '' : ((+value * 9 / 5) + 32).toFixed(digits);
                break;
            case 'avg_consumption':
                value = isNaN(+value) ? '' : (+value * 0.0042514).toFixed(digits);
                um = 'ga/mi';
                break;
            case 'weight': 
                value = (+value * 2.20462262185).toFixed(digits);
                um = "lbs";
                break;
            default:
                um = '';
                break;
        }

        return value + ' ' + um;
    }
}
