import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-select-daterange-dialog',
  templateUrl: './select-daterange-dialog.component.html',
  styleUrls: ['./select-daterange-dialog.component.scss']
})

export class SelectDaterangeDialogComponent {

    public selectDates = {
        startDate: moment().subtract(moment.duration(30, 'days')).startOf('day').format('YYYY-MM-DD'),
        endDate: moment().endOf('day').format('YYYY-MM-DD')
    };

    @Output() sendRangeEventEmitter: EventEmitter<any> = new EventEmitter();
    public archiveLoadWait = false;

    constructor(
        public dialogRef: MatDialogRef<SelectDaterangeDialogComponent>,
        private translateService: TranslateService
    ) { }

    public onCancel(): void {
        this.dialogRef.close({ simple: true });
    }

    public setArchivePeriod(event: any) {
        this.selectDates.startDate = (event.dateBegin.indexOf(' ') !== -1 ?
            event.dateBegin.substr(0, event.dateBegin.indexOf(' ')) : event.dateBegin );
        this.selectDates.endDate = (event.dateEnd.indexOf(' ') !== -1 ?
            event.dateEnd.substr(0, event.dateEnd.indexOf(' ')) : event.dateEnd );
    }

    public getLocale(): String {
        const lang = localStorage.getItem('currentLang');
        return (lang) ? lang.substring(0, 2) : 'ro';
    }

    public getTranslationFor(label: string, value = {}): string {
        let result = '';
        this.translateService.get(label, value).subscribe((response: string) => result = response);

        return result;
    }

    public onSubmit() {
        this.sendRangeEventEmitter.emit(this.selectDates);
    }
}
