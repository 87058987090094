import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
    selector: 'app-recurentpayment',
    templateUrl: './recurentpayment.component.html',
    styleUrls: ['./recurentpayment.component.scss']
})
export class RecurentPaymentComponent implements OnInit {

    public isRecurentPaymentActive: boolean;
    public recurentMaxAmount: any;
    public expirationDate: string;
    public amountErrorMessageShown: boolean;

    constructor(public dialogRef: MatDialogRef<RecurentPaymentComponent>, private readonly translateService: TranslateService, @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {
        this.isRecurentPaymentActive = false;
        this.recurentMaxAmount = 0;
        this.expirationDate = moment().add(2, 'years').format('YYYY-MM-DD');
        this.amountErrorMessageShown = false;
    }

    public setExpireDate(event) {
        this.expirationDate = event;
    }

    public getTranslationFor(label: string): String {
        return this.translateService.instant(label);
    }

    public onSubmit() {
        this.amountErrorMessageShown = false;
        if (this.isRecurentPaymentActive && (this.recurentMaxAmount === 0 || isNaN(this.recurentMaxAmount) || this.recurentMaxAmount === "")) {
            this.amountErrorMessageShown = true;
            return;
        }

        this.dialogRef.close({recurent: this.isRecurentPaymentActive, maxAmount: this.recurentMaxAmount, expirationDate: this.expirationDate});
    }
}
