import { Component, OnInit, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { Chart } from 'chart.js';
import 'chartjs-plugin-zoom';
import { take } from 'rxjs/internal/operators/take';
import { first } from 'rxjs/internal/operators/first';

import { UserPreferencesService } from 'app/service/userpreferences.service';
import { MapService } from 'app/modules/service/shared/map.service';

import { RefuelingChart } from 'app/modules/model/map/refuelingchart.model';

@Component({
    selector: 'app-refueling-chart',
    templateUrl: './refueling-chart.component.html',
    styleUrls: ['./refueling-chart.component.scss']
})
export class RefuelingChartComponent implements OnInit {

    public chart: Chart;
    public chartLabels: string[];
    public refuelings: number[];
    public dateFormat: string;

    constructor(private mapService: MapService, private elementRef: ElementRef, private userPreferencesService: UserPreferencesService) { }

    ngOnInit() {
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
            this.userPreferencesService.userPreferences.dateFormat.toUpperCase() + ' HH:mm:ss' :
            this.userPreferencesService.defaultPreferences.dateFormat.toUpperCase() + ' HH:mm:ss';

        this.setChartVerticalLine();
        const ctx = this.elementRef.nativeElement.querySelector('#refuelingsChart');

        this.mapService.refuelingChartSubject.pipe(first()).subscribe(
            (chartData: RefuelingChart[]) => {
                this.chartLabels = chartData.map((data: RefuelingChart) => this.mapService.setDateOffset(data.devicetime));
                this.refuelings = chartData.map(
                    (data: RefuelingChart) => Number((data.fuel_level) ? data.fuel_level.toFixed(2) : data.fuel_level)
                );
            }
        );

        this.mapService.refuelingValuesChartSubject.pipe(take(1)).subscribe(
            (refuelingValues: { dateBegin: string, dateEnd: string, value: number }[]) => {
                if (this.chart) {
                    this.chart.destroy();
                }
                this.chart = this.setChart(ctx, this.chartLabels, this.refuelings, refuelingValues);
                this.mapService.refuelingChartImageSubject.next(this.chart);
            }
        );
    }

    private setChartVerticalLine(): void {
        Chart.defaults.LineWithLine = Chart.defaults.line;
        Chart.controllers.LineWithLine = Chart.controllers.line.extend({
            draw: function (ease) {
                Chart.controllers.line.prototype.draw.call(this, ease);

                if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
                    const activePoint = this.chart.tooltip._active[0];
                    const cTx = this.chart.ctx;
                    const x = activePoint.tooltipPosition().x;
                    const topY = this.chart.scales['y-axis-0'].top;
                    const bottomY = this.chart.scales['y-axis-0'].bottom;

                    cTx.save();
                    cTx.beginPath();
                    cTx.moveTo(x, topY);
                    cTx.lineTo(x, bottomY);
                    cTx.lineWidth = 1;
                    cTx.strokeStyle = '#07C';
                    cTx.stroke();
                    cTx.restore();
                }
            }
        });

    }

    private showRefuelingsOnChartPlugin(refuelingValues: { dateBegin: string, dateEnd: string, value: number }[]): any {
        const refuelingImage = new Image();
        const leakImage = new Image();
        refuelingImage.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAABmJLR0QA/wD/AP+gvae' +
        'TAAAACXBIWXMAACE4AAAhOAFFljFgAAAAB3RJTUUH4wwaBiIHmws2RwAAAhNJREFUSMe9lrtOG0EUhr8ZgRGFFVHRAwXOC2S6SBRRUqUEJApH' +
        'ERUpUkZCm4JpUqZwG2EhhM0bWBRGdAsPAC5w6myFIEgIjLQpfBbG671MkOBvdmfO5d85cy6rcBEGYKz7XASWgO/ALOOIgC2gi7G9MXuByiBQwB' +
        'zQAN7jjw7wBfiNsbFLpEcIhlgBToUg9iSIRf9U7B9PBKgRgjBYBraBaZ6OG+ATxu4nB1DyouQLfnkQnAMLHkSfgTbGxkqONC9HrZQYHwA/gG/A' +
        'uxLdO+A10NcSqoYHwS2wjrGHwLqsi1ABGhiLkjQ9k8tTOZeqJM5NJxvrcn9ldjUtdUCJYhPYS8n2ZF/lZGHib0kRBn9yCi3BMbCGsedjkjBYAH' +
        'aBNwX2kS4huAA2Hwgk7x+ew/1N0cvDrC65vBZwMlawTqGJvFXkRBEGeVXdBL5i7GVp+YXBK+AnUM8STxSEqQtcEwZVYADcYmzsOFbAFDAJXIv+' +
        'R2Am7SwvXDPADnAPXMl7NaVTlf0r0dvJIkhIIs9+FGek942HbaRlHjwntrTEkv9o675I/HU1xvZk4KgCgwHG/h3ZGa4HhZkLHYzt+XbhHnAknT' +
        'VpIxXgLbBY1oWfMk98B1dqnrzQZHSJVqXaKwVtPK9b3wF1jG25fnVGL2pLLDueBI+XPLRrpz9cO9mSCGKgj7EfgBqwUVCwkchrot9P/w4B/ANk' +
        'W8ikTI2ATgAAAABJRU5ErkJggg==';
        leakImage.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAABmJLR0QA/wD/AP+gvaeTAAA' +
        'ACXBIWXMAACE4AAAhOAFFljFgAAAAB3RJTUUH4wwaBiEdTUSc/gAAAfFJREFUSMe9lr9qG0EQxn+zUVI4LgypFpIuBneGgAxpXMbEq0dwkz5SY' +
        'bDfwI1L6wECxo+QxURFirSBpDUINyawuDAYLLnIv02RWThWd6cVif3BFXc333y7N7PfnJAhWocET7SuA6wCa8AJsMQsboEd4AwYS/A/E78KyQR' +
        'Ego/RuldAD+hTjiHwXoIfpTwzIhWBPnCUHucLaUA1biDBD6tCJhPYqwhQKJDHHUXr9jSfAEilBv1M4F+RdvR3BVqDDy2E78CXtHPFb+AF8KiFt' +
        'yXBjzraRb05q7qU4F/WdOIF8KyF14vWfTTapn0tXsk3p7BmUfOuGj0HixSZBZthzehBu0ucdBpOcgSugQd6TRoSTIAp8EuvlZovstRpIE+BDeA' +
        'b8LClXl1N+gN4CnwFlvMg00BeBjwgEvyNBD+J1tV53ESCv1EhXyfQJgLwHDiM1j0GZk1P7/X9ocazqIgB3gLraeX5ThTrGteYS6J104biJ3wGX' +
        'kvwVzWH8QlwqrVpwq3RedCGLnCcjDRaRzI+fd6dw98xOnBo6aAIbEfrdpN1q8PuAttzeABnBhjrwJlnHQdZAxwUWM4QGJe6cMIn4A3wDtgsiN+' +
        'S4EdG+30EDApIm8B5ocBARzFGB5ZI8ENg/z/51X5lBN/PjL/fv5W7/O/6A8Ge6Rr/51mmAAAAAElFTkSuQmCC';

        return {
            id: 'RefuelingValues',
            afterDraw: function (chartInstance) {
                const ctx = chartInstance.chart.ctx;
                ctx.font = Chart.helpers.fontString(15, 'bold', Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'top';
                const dataset = chartInstance.data.datasets[0];
                refuelingValues.forEach(
                    (dataValue: { dateBegin: string, dateEnd: string, value: number }) => {
                        const labelIndex = chartInstance.data.labels.indexOf(dataValue.dateEnd);
                        if (labelIndex !== -1) {
                            const model = dataset._meta[Object.keys(dataset._meta)[0]].data[labelIndex]._model;
                            ctx.fillStyle = dataValue.value > 0 ? '#00C87D' : '#FF194B';
                            ctx.fillText((Math.abs(dataValue.value)).toFixed(2), model.x, model.y - 50);
                            if (dataValue.value > 0) {
                                ctx.drawImage(refuelingImage, model.x - 13, model.y - 30);
                            } else {
                                ctx.drawImage(leakImage, model.x - 13, model.y - 30);
                            }
                        }
                    }
                );
            }
        };
    }

    private setChart(
        chartRef: ElementRef, labels: string[],
        refuelings: number[],
        refuelingValues: { dateBegin: string, dateEnd: string, value: number }[]
    ): Chart {

        const color = Chart.helpers.color;

        return new Chart(chartRef, {
            type: 'LineWithLine',
            data: {
                labels: labels,
                datasets: [
                    {
                        data: refuelings,
                        pointRadius: 0,
                        pointBackgroundColor: 'rgba(0,75,125, 1)',
                        backgroundColor: color('rgb(255,0,4)').alpha(0.7).rgbString(),
                        fill: false,
                        borderColor: ['rgba(0,75,125, 1)'],
                        borderWidth: 2,
                    }
                ]
            },
            options: this.setChartOptions(moment(labels[0]).unix() * 1000, moment(labels[labels.length - 1]).unix() * 1000),
            plugins: [this.showRefuelingsOnChartPlugin(refuelingValues)]
        });
    }

    private setChartOptions(startDate: number, endDate: number): any {
        return {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                mode: 'index',
                intersect: false,
                position: 'average'
            },
            hover: {
                mode: 'index',
                intersect: false,
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 55,
                    bottom: 0
                }
            },
            legend: {
                display: false,
            },
            animation: {},
            scales: {
                xAxes: [
                    {
                        type: 'time',
                        time: {
                            tooltipFormat: this.dateFormat,
                            displayFormats: {
                               'millisecond': 'HH:mm',
                               'second': 'HH:mm',
                               'minute': 'HH:mm',
                               'hour': 'HH:mm',
                               'day': 'HH:mm',
                               'week': 'HH:mm',
                               'month': 'HH:mm',
                               'quarter': 'HH:mm',
                               'year': 'HH:mm',
                            }
                        },
                        ticks: {
                            fontColor: 'rgb(0,0,0)',
                            autoSkip: true,
                            autoSkipPadding: 5,
                            maxRotation: 0
                        }
                    },
                    {
                        type: 'time',
                        distribution: 'linear',
                        time: {
                            unit: 'day',
                            tooltipFormat: 'D MMM HH:mm'
                        },
                        ticks: {
                            autoSkip: true,
                            autoSkipPadding: 5,
                            maxRotation: 0,
                            fontColor: 'rgb(0,0,0)'
                        },
                        gridLines: {
                            color: 'rgba(0,0,0,1)'
                        }
                    }
                ],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        fontColor: 'rgb(0,0,0)'
                    }
                }]
            },
            plugins: {
                zoom: {
                    pan: {
                        enabled: true,
                        mode: 'x',
                        rangeMax: {
                            x: (endDate + ((60 * 60 * 2 * 1000))),
                        },
                        rangeMin: {
                            x: (startDate - ((60 * 60 * 2 * 1000))),
                        }
                    },
                    zoom: {
                        enabled: true,
                        mode: 'x',
                        speed: 0.5,
                        rangeMax: {
                            x: (endDate + ((60 * 60 * 2 * 1000))),
                        },
                        rangeMin: {
                            x: (startDate - ((60 * 60 * 2 * 1000))),
                        }
                    }
                }
            }
        };
    }
}
