<h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18">
    {{ 'Rer.objects.addClientAddress' | translate }}
</h4>
<div mat-dialog-content class="content pl-4 pr-4"></div>
<div mat-dialog-actions class="mt-4 p-2">
    <div class="buttons d-flex align-items-center justify-content-between pt-2">
        <div class="button-component bgcolor-white" (click)="dialogRef.close(false)">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="button-component bgcolor-blue" (click)="onAdd()">
            <div class="inner inner-blue user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'add' | translate }}</span>
            </div>
        </div>
    </div>
</div>