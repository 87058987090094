<h1 mat-dialog-title class="p-2"> {{ 'dateRange' | translate }} </h1>
<div mat-dialog-content style="min-height: 200px">
    <div *ngIf="!archiveLoadWait">
        <div class="row ml-0 mr-0 mt-4">
            <div class="col-md-12">
                <app-datepicker-range appendTo="body" [dateRange]="[selectDates.startDate, selectDates.endDate]"
                    (selectDateEventEmitter)="setArchivePeriod($event)" [time]="false" [alignCenter]="true"
                    [justifyCenter]="true" [locale]="getLocale()" [label]="getTranslationFor('dateRange')">
                </app-datepicker-range>
            </div>
        </div>
    </div>

    <mat-spinner *ngIf="archiveLoadWait" style="margin: auto;">
    </mat-spinner>
</div>
<div mat-dialog-actions class="pt-2 mt-4 d-flex align-items-center justify-content-center">
    <span class="mr-3 eButton cancel pointer pb-2 pt-2 pl-3 pr-3" (click)="onCancel()">
        {{ 'cancel' | translate }}
    </span>
    <span class="mr-3 eButton pointer primary pb-2 pt-2 pl-3 pr-3" (click)="onSubmit()">
        {{ 'loadArchive' | translate }}
    </span>

</div>
