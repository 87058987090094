<h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18">
    {{ 'Route.saveRoute' | translate }}</h4>
<div mat-dialog-content class="form-container p-4 content m-0">
    <div class="mt-4">
        <app-datepicker-range (selectDateEventEmitter)="onSelectVisibleDate($event)" [time]="true"
            [locale]="getLocale()" [alignCenter]="true" [justifyCenter]="true"
            [dateRange]="[data.visible_from, data.visible_to]" [label]="'Perioada de visibilitate'">
        </app-datepicker-range>
    </div>
    <div class="mt-4">
        <mat-form-field class="w-100">
            <input type="address" matInput [(ngModel)]="data.name"
                placeholder="{{ 'Route.addRouteDialog.name' | translate }}" autocomplete="off">
        </mat-form-field>
    </div>
    <div class="mt-4">
        <mat-form-field class="w-100 mb-3">
            <textarea matInput class="color-blue" [(ngModel)]="data.description">
            </textarea>
            <mat-placeholder class="placeholder">{{ 'Route.addRouteDialog.description' | translate }}</mat-placeholder>
        </mat-form-field>
    </div>
    <div *ngIf="errorMessage.length > 0" class="mt-1 mb-1">
        <p class="m-0 p-0 text-danger" *ngFor="let error of errorMessage">{{ error }}</p>
    </div>
</div>
<div mat-dialog-actions class="mt-4 mb-0 p-3">
    <div class="w-100 buttons d-flex align-items-center justify-content-between pt-3">
        <div class="button-component ml-2 utility-green-bg-color" (click)="dialogRef.close(false)">
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="button-component ml-2 utility-green-bg-color" (click)="onSubmit()">
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'submit' | translate }}</span>
            </div>
        </div>
    </div>
</div>