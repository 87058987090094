import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from '../modules/map/interface/duration.interface';
import * as moment from 'moment';
import 'moment-duration-format';

@Pipe({
    name: 'stateduration'
})
export class VehicleStateDuration implements PipeTransform {

    transform(value, now) {
        let sinceDuration;

        if (value === 'moreThanADay') {
            sinceDuration = 'Map.vehicleInfo.moreThanADay';
        } else {
            const since = moment(value);
            const diff = now.diff(since, 'minutes');
            const duration = moment.duration(diff, 'minutes') as Duration;

            sinceDuration = duration.format('d[d] h[h] m[m]');
        }

        return sinceDuration;
    }
}
