import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-review-dialog',
    templateUrl: './review-dialog.component.html',
    styleUrls: ['./review-dialog.component.scss']
})
export class ReviewDialogComponent {

    public review: any;
    public type: string;
    public submodule: string;
    public dateFormat: string;

    constructor(
        public dialogRef: MatDialogRef<ReviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) 
    {
        this.review = data.review;
        this.type = data.type;
        this.submodule = data.submodule;
        this.dateFormat = data.dateFormat;
    }

    public calculateStarsWidth(rating: number){
        return rating / 5 * 100;
    }
}
