<h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18">
    <span>
        {{ (type == "received" ? 'PGS.reviewDialog.reviewReceived' : 'PGS.reviewDialog.reviewSent') | translate }} {{ review.created_at  | offset:true | date:dateFormat + ' HH:mm' }}
    </span>
</h4>
<div mat-dialog-content class="p-4 content pgs-review-dialog">
    <dl class="row">
        <dt class="col-sm-5 text-truncate">{{ "PGS.reviewDialog.rating" | translate }}</dt>
        <dd class="col-sm-7 text-truncate"><div class="stars-outer"><div [ngStyle]="{'width': calculateStarsWidth(review.rating)+'%'}" class="stars-inner"></div></div></dd>
      
        <dt class="col-sm-5 text-truncate" 
            *ngIf="type =='received' || type=='received_client'|| type=='received_transporter' || type=='received_loading_team' || submodule=='dashboard'">
            {{ "PGS.reviewDialog.receivedFrom" | translate }}
        </dt>
        <dd class="col-sm-7 text-truncate"*ngIf="submodule=='delivery' || submodule=='dashboard'">
            {{ type=='received_transporter' || type=="transporter" ? review.pgs_client.name : review.pgs_transporter.name }}
        </dd>
        
        <dt class="col-sm-5 text-truncate" 
            *ngIf="type=='received_client' || type=='received_transporter' || type=='received_loading_team' || (type=='sent' && submodule=='driver') ||  submodule=='dashboard'">
            {{ "PGS.reviewDialog.sentTo" | translate }}
        </dt>
        <dd class="col-sm-7 text-truncate"*ngIf="submodule=='delivery'|| submodule=='dashboard'">
            {{ type=='received_transporter' ? review.pgs_transporter.name : type=='received_client' ? review.pgs_client.name : review.loading_team_code }}
        </dd>
        <dd class="col-sm-7 text-truncate"*ngIf="submodule=='client' || type=='client'">{{review.pgs_transporter.name}}</dd>
        <dd class="col-sm-7 text-truncate"*ngIf="(submodule=='driver' || submodule=='transporter' || submodule == 'vehicle')  && (type=='sent' || type=='received')">{{review.pgs_client.name}}</dd>
        <dd class="col-sm-7 text-truncate"*ngIf="(submodule=='driver' || submodule=='transporter')  && type=='sent_loading_team'">{{review.loading_team_code}}</dd>
      
        <dt class="col-sm-5 text-truncate" *ngIf="review.pgs_delivery">{{ "PGS.reviewDialog.delivery" | translate }}</dt>
        <dd class="col-sm-7 text-truncate" *ngIf="review.pgs_delivery">{{review.pgs_delivery.number}}</dd>
      
        <dt class="col-sm-5 text-truncate" *ngIf="review.pgs_client_address">{{ "PGS.reviewDialog.clientAddress" | translate }}</dt>
        <dd class="col-sm-7 text-truncate" *ngIf="review.pgs_client_address">{{review.pgs_client_address.name}}</dd>

        <dt class="col-sm-5 text-truncate" *ngIf="review.pgs_driver">{{ "PGS.reviewDialog.driver" | translate }}</dt>
        <dd class="col-sm-7 text-truncate" *ngIf="review.pgs_driver">{{review.pgs_driver.name ? review.pgs_driver.name : review.pgs_driver.phone_no }}</dd>

        <dt class="col-sm-5 text-truncate" *ngIf="review.pgs_vehicle">{{ "PGS.reviewDialog.vehicle" | translate }}</dt>
        <dd class="col-sm-7 text-truncate" *ngIf="review.pgs_vehicle">{{review.pgs_vehicle.license_plate}}</dd>

        <dt class="col-sm-5 text-truncate">{{ "PGS.reviewDialog.comment" | translate }}</dt>
        <dd class="col-sm-7">{{review.comment}}</dd>
      </dl>
</div>
<div mat-dialog-actions class="mt-4 p-3">
    <div class="d-flex align-items-center justify-content-center pt-3">
        <div class="button-component ml-2" (click)="this.dialogRef.close(false)">
            <div class="user-no-select">
                <span class="color-grey font-weight-bold">{{ 'close' | translate }}</span>
            </div>
        </div>
    </div>
</div>
