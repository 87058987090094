<p class="pt-4 pb-4 bgcolor-blue text-center font-weight-bold color-white text-uppercase overflow-visible">
    {{ "Accounting.payment.recurency" | translate }}</p>
<div mat-dialog-content>
    <div class="row mt-3 ml-2 mr-2">
        <div class="col-md-12">
            <mat-checkbox id="is_recurent" name="is_recurent" [(ngModel)]="isRecurentPaymentActive" class="mb-0"> {{ 'Accounting.payment.activateRecurentPayment' | translate }}</mat-checkbox>
        </div>
    </div>
    <div class="row mt-4 ml-2 mr-2">
        <div class="col-md-12">
            <mat-form-field class="w-100">
                <input type="details" matInput placeholder="{{ 'Accounting.payment.recurentMaxAmount' | translate }}" [disabled]="!isRecurentPaymentActive" [(ngModel)]="recurentMaxAmount">
            </mat-form-field>
        </div>
    </div>
    <div class="row mt-2 ml-2 mr-2">
        <div class="col-md-12 text-danger">
            <span *ngIf="amountErrorMessageShown && isRecurentPaymentActive">{{ 'Accounting.payment.maxAmountError' | translate}}</span>
        </div>
    </div>
    <div class="row mt-4 ml-2 mr-2">
        <div class="col-md-12 text-center">
            <app-datepicker [label]="getTranslationFor('Accounting.payment.recurentExpirationDate')" [includeTime]="false"
                (selectDateEventEmitter)="setExpireDate($event)"
                [disabled]="!isRecurentPaymentActive"
                [date]="expirationDate">
            </app-datepicker>
        </div>
    </div>
</div>
<div mat-dialog-actions class="mt-4 text-center">
    <span class="mr-3 btn-flat pointer" (click)="dialogRef.close(false)">{{ 'close' | translate }}</span>
    <span class="mr-3 btn btn-success pointer" (click)="onSubmit()">{{ 'continue' | translate }}</span>
</div>
