import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

import { AccountingService } from 'app/modules/service/shared/accounting.service';

import { NotificationComponent } from '../notification/notification.component';

@Component({
    selector: 'app-invoicelistfilter',
    templateUrl: './invoicelistfilter.component.html',
    styleUrls: ['./invoicelistfilter.component.scss']
})
export class InvoicelistfilterComponent implements OnInit {
    @Input() isVisible: boolean = false;

    public invoicesPaid = 0;
    public invoicesDue = 0;
    public total_ballance = 0;
    public invoicesCount = 0;
    public outstanding_total_ballance = 0;
    public selectedInvoiceList:any = ['SAHU', 'ASHU', 'HUAV', 'FFHU', 'PPHU'];
    public isSelectAllInvoices = true;
    public optionsInvoiceList = [
        { value: 'SAHU', label: 'SAHU', text: 'Facturi Recuperare TVA HU' },
        { value: 'ASHU', label: 'ASHU', text: 'Facturi de avans' },
        { value: 'HUAV', label: 'HUAV', text: 'Facturi stornare avans' },
        { value: 'FFHU', label: 'FFHU', text: 'Facturi comision' },
        { value: 'PPHU', label: 'PPHU', text: 'Facturi proforme' },
    ];

    public filter = 'allInvoices';
    @Output() clickFilterEventEmitter: EventEmitter<string> = new EventEmitter();
    @Output() selectedInvoiceListEventEmitter: EventEmitter<any> = new EventEmitter();

    constructor(private accountService: AccountingService, public actionDialog: MatDialog) { }

    ngOnInit() {
        this.accountService.invoiceList$.subscribe(
            (data) => {
                if (!data) { 
                    return;
                }
                this.total_ballance = 0;
                this.outstanding_total_ballance = 0;
                this.invoicesCount = data.length;
                this.invoicesPaid = data.filter( elem => elem.payment_status === 2).length;
                this.invoicesDue = data.filter(
                    elem => elem.payment_status !== 2 && elem.payment_status !== 1 && this.isDateDue(elem['date_due'])).length;
                data.forEach(invoice => {
                    if (invoice.payment_status !== 2) {
                        this.total_ballance += +invoice.total - (invoice.paid_amount ? invoice.paid_amount : 0);
                        if (moment().diff(invoice['date_due'], 'days') > 0) {
                            this.outstanding_total_ballance += +invoice.total - (invoice.paid_amount ? invoice.paid_amount : 0);
                        }
                    }
                });
            },
            // (error) => console.log('Error: ' + error)
        );
    }

    public isDateDue(dateDue: string): boolean {
        return (moment().diff(dateDue, 'days') > 0);
    }

    public filterBy(filter: string): void {
        if (this.filter === filter) {
            return;
        }
        this.filter = filter;
        this.clickFilterEventEmitter.next(filter);
    }

    public onChangeInvoiceList() {
        this.isSelectAllInvoices = (this.optionsInvoiceList.length === this.selectedInvoiceList.length);
    }

    public onSelectAllInvoiceTypes(event: any): void {
        this.selectedInvoiceList = this.isSelectAllInvoices ? this.optionsInvoiceList.map((el: any) => el.value) : [];
    }

    public preventEventToCheckbox(event): void {
        event.preventDefault();
    }

    public onFilterInvoiceType() {
        this.selectedInvoiceListEventEmitter.next(this.selectedInvoiceList);
    }

    public showInvoyceTypeInfo() {
        const message = `<ul>
        <li><b>SAHU</b> - Facturi Recuperare TVA HU</li>
        <li><b>ASHU</b> - Facturi de avans</li>
        <li><b>HUAV</b> - Facturi stornare avans</li>
        <li><b>FFHU</b> - Facturi comision</li>
        <li><b>PPHU</b> - Facturi proforme</li>
        </ul>`;
        this.actionDialog.open(
            NotificationComponent,
            {
                maxWidth: '800px',
                minWidth: '480px',
                panelClass: 'custom-dialog-container',
                data: { success: 1, headermessage: 'Info', message: '', innerHtml: message }
            }
        );
    }
}
