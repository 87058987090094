import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmActionComponent } from '../confirm-action/confirm-action.component';

@Component({
    selector: 'app-btpayment',
    templateUrl: './btpayment.component.html',
    styleUrls: ['./btpayment.component.scss']
})
export class BtpaymentComponent {

    constructor(
        public dialogRef: MatDialogRef<BtpaymentComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    public onCancel(): void {
        const dialogRef = this.dialog.open(
            ConfirmActionComponent,
            {
                width: '450px',
                panelClass: 'custom-dialog-container',
                data: { headerMessage: 'close', contentMessage: this.data.contentMessage }
            }
        );
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dialogRef.close(false);
            }
        });
    }
}
