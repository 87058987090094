import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-add-rer-client-address',
    templateUrl: './add-rer-client-address.component.html',
    styleUrls: ['./add-rer-client-address.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddClientAddressComponent implements OnInit {

    public showMandatory: boolean;

    constructor(
        public dialogRef: MatDialogRef<AddClientAddressComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {
        this.showMandatory = false;
    }

    public onAdd(): void {
        this.showMandatory = (this.data.name && this.data.code && this.data.phone && this.data.delegate_name) ? false : true;
        if (!this.showMandatory) {
            this.dialogRef.close(this.data);
        }
    }

}
