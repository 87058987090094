import { RouteInterface } from '../../route/interface/route.interface';
import * as moment from 'moment';

export class Route implements RouteInterface {

    constructor(
        public id = null,
        public device_id = null,
        public routes = [],
        public name = null,
        public visible_from = moment().format('YYYY-MM-DD HH:mm:ss'),
        public visible_to = moment().format('YYYY-MM-DD HH:mm:ss'),
        public description = null,
        public color = null
    ) { }
}
