import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-select-card-issuer',
    templateUrl: './select-card-issuer.component.html',
    styleUrls: ['./select-card-issuer.component.scss'],
    imports: [ CommonModule, FormsModule, TranslateModule, MatRadioModule ],
    standalone: true
})
export class SelectCardIssuerComponent  {

    public issuingBank: string[] = ['transilvaniaBank', 'anotherBank'];
    public selectedIssuingBank: string;

    constructor(public dialogRef: MatDialogRef<SelectCardIssuerComponent>, private readonly translateService: TranslateService) {}

    public getTranslationFor(label: string): String {
        return this.translateService.instant(label);
    }

    public onSubmit() {
        if (this.selectedIssuingBank) {
            this.dialogRef.close(this.selectedIssuingBank);
        }
    }
}
