<div (click)="filterBy('allInvoices')" [ngClass]="filter === 'allInvoices' ? 'focus' : 'pointer'"
    class="data-info font-family-open-sans d-flex justify-content-between mb-2 align-items-center pl-2 pr-1">
    <span class="data-info-text font-size-15">{{ 'Accounting.allInvoices' | translate  }}</span>
    <span class="data-info-count font-size-11 color-white text-center">{{ invoicesCount }}</span>
</div>
<div (click)="filterBy('paidInvoices')" [ngClass]="filter === 'paidInvoices' ? 'focus' : 'pointer'"
    class="data-info font-family-open-sans d-flex justify-content-between mb-2 align-items-center pl-2 pr-1">
    <span class="data-info-text font-size-15">{{ 'Accounting.invoicesPaid' | translate  }}</span>
    <span class="data-info-count font-size-11 color-white text-center">{{ invoicesPaid }}</span>
</div>
<div (click)="filterBy('dueInvoices')" [ngClass]="filter === 'dueInvoices' ? 'focus' : 'pointer'"
    class="data-info font-family-open-sans d-flex justify-content-between mb-4 align-items-center pl-2 pr-1">
    <span class="data-info-text font-size-15">{{ 'Accounting.invoicesDue' | translate  }}</span>
    <span class="data-info-count font-size-11 color-white text-center">{{ invoicesDue }}</span>
</div>
<div *ngIf="isVisible">
    <div class="d-flex align-items-center mt-3">
        <div class="ng-select-wrapper w-100">
            <ng-select placeholder="{{ 'Accounting.filter' | translate }}" appendTo="body" [(ngModel)]="selectedInvoiceList" [clearable]="false"
                [(items)]="optionsInvoiceList" [closeOnSelect]="false" [multiple]="true" (change)="onChangeInvoiceList()"
                bindLabel="label" bindValue="value">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <div class="ng-value">
                                <span class="ng-value-label">{{ item.label }}</span>
                                <span class="ml-1 ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </div>
                        </ng-container>
                    </ng-template>
                    <ng-template ng-header-tmp>
                        <div style="margin-right: 10px">
                            <mat-checkbox (change)="onSelectAllInvoiceTypes($event)" [(ngModel)]="isSelectAllInvoices">
                                <span class="ml-2 color-corbeau">{{ 'selectAll' | translate }}</span>
                            </mat-checkbox>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <mat-checkbox (click)="preventEventToCheckbox($event)" [(ngModel)]="item$.selected">
                            <span>{{ item.label }}</span>
                        </mat-checkbox>
                    </ng-template>
            </ng-select>
        </div>
        <i class="icon-cts-password-forgot color-blue font-size-18 pointer ml-2" (click)="showInvoyceTypeInfo()"></i>
    </div>
    <div (click)="onFilterInvoiceType()" class="button-component utility-green-bg-color mt-2 width-176">
        <div class="inner inner-green user-no-select text-center pb-2 pt-2 pl-4 pr-4">
            <span class="color-white ml-0">{{ 'Accounting.filter' | translate }}</span>
        </div>
    </div>
</div>