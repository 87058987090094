<div *ngIf="!(masspayment && selection.length > 0)" class="datatTableFilter p-2 d-flex align-items-center">
    <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
    <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value)"
        placeholder="{{ 'quicksearch' | translate }}">
</div>
<div *ngIf="masspayment && selection.length > 0" class="d-flex align-items-center alert bgcolor-blue p-2 mb-0">
    <span class="font-family-montserrat color-white font-weight-bold">{{ getTranslationFor('Accounting.invoice.youselected')
        .replace('[#count#]', selection.length)
        .replace('[#value#]', totalSelected.toFixed(2) )
        .replace('[#currency#]', 'RON' ) }}</span>
    <div class="button-component utility-green-bg-color m-auto width-176" (click)="payAllChecked()"
        style="width: unset;">
        <div class="inner inner-green user-no-select text-center pb-2 pt-2 pl-4 pr-4">
            <span class="color-white ml-0">{{ 'Accounting.invoice.payall' | translate }}</span>
        </div>
    </div>
</div>
<mat-table [dataSource]="dataSource" class="invoice-list">
    <ng-container matColumnDef="select">
        <mat-header-cell class="d-flex col-1" *matHeaderCellDef></mat-header-cell>
        <mat-cell class="d-flex col-1" *matCellDef="let row">
            <mat-checkbox [disabled]="row.payment_status === 1 || row.payment_status === 2 || +row.total <= 0"
                (change)="toggle(row)" (click)="$event.stopPropagation()" [checked]="selection.indexOf(row) !== -1"
                [indeterminate]="selection.indexOf(row) === -1">
            </mat-checkbox>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="serial">
        <mat-header-cell *matHeaderCellDef> {{ 'Accounting.invoice.number' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <span class="mobile-label pr-2">{{ 'Accounting.invoice.number' | translate }}</span>
            <span
                [ngClass]="{ 'color-red-primary' : isDateDue(element.date_due) && element.payment_status !== 2 && +element.total > 0} ">{{ element.serial }}
                {{ element.number}}</span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="company">
        <mat-header-cell *matHeaderCellDef> {{ 'Accounting.invoice.company' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <span class="mobile-label pr-2">{{ 'Accounting.invoice.company' | translate }}</span>
            <span
                [ngClass]="{ 'color-red-primary' : isDateDue(element.date_due) && element.payment_status !== 2 && +element.total > 0} ">{{ element.company.name }}
            </span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef> {{ 'Accounting.invoice.date' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <span class="mobile-label pr-2">{{ 'Accounting.invoice.date' | translate }}</span>
            <span
                [ngClass]="{ 'color-red-primary' : isDateDue(element.date_due) && element.payment_status !== 2 && +element.total > 0} ">{{ element.date | date}}
            </span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="paid_amount">
        <mat-header-cell *matHeaderCellDef> {{ 'Accounting.invoice.paidamount' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <span class="mobile-label pr-2">{{ 'Accounting.invoice.paidamount' | translate }}</span>
            <span
                [ngClass]="{ 'color-red-primary' : isDateDue(element.date_due) && element.payment_status !== 2 && +element.total > 0} ">{{ (element.paid_amount ? element.paid_amount : 0) | number }}
                RON
            </span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="paid_date">
        <mat-header-cell *matHeaderCellDef> {{ 'Accounting.invoice.paid_date' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <span class="mobile-label pr-2">{{ 'Accounting.invoice.paid_date' | translate }}</span>
            <span
                [ngClass]="{ 'color-red-primary' : isDateDue(element.date_due) && element.payment_status !== 2 && +element.total > 0} ">
                {{ element.payment_date | date }} </span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="due_date">
        <mat-header-cell *matHeaderCellDef> {{ 'Accounting.invoice.due_date' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <span class="mobile-label pr-2">{{ 'Accounting.invoice.due_date' | translate }}</span>
            <span
                [ngClass]="{ 'color-red-primary' : isDateDue(element.date_due) && element.payment_status !== 2 && +element.total > 0} ">{{ element.date_due | date }}
            </span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="totalvalue">
        <mat-header-cell *matHeaderCellDef> {{ 'Accounting.invoice.totalvalue' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <span class="mobile-label pr-2">{{ 'Accounting.invoice.totalvalue' | translate }}</span>
            <span
                [ngClass]="{ 'color-red-primary' : isDateDue(element.date_due) && element.payment_status !== 2 && +element.total > 0} ">{{ +element.total | number }}
                RON
            </span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="payment-difference">
        <mat-header-cell *matHeaderCellDef> {{ 'Accounting.invoice.payment-difference' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <span class="mobile-label pr-2">{{ 'Accounting.invoice.payment-difference' | translate }}</span>
            <span
                [ngClass]="{ 'color-red-primary' : isDateDue(element.date_due) && element.payment_status !== 2 && +element.total > 0} ">{{ +element.total - +(element.paid_amount ? element.paid_amount : 0) | number }}
                RON
            </span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell class="d-flex justify-content-start" *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" class="d-flex justify-content-start">
            <i title="{{ 'download' | translate }}" class="ml-2 mr-2 icon-tmss-list-action-download pointer"
                (click)="downloadInvoice(element.id)">
            </i>
            <div *ngIf="element.payment_status !== 1 && element.payment_status !== 2 && +element.total > 0"
                class="button-component utility-green-bg-color height-32" (click)="payInvoice(element)">
                <div class="d-flex inner inner-green user-no-select pt-1 pb-1 pl-2 pr-2">
                    <span
                        class="color-white m-0 white-space-nowrap">{{ 'Accounting.invoice.paythis' | translate }}</span>
                </div>
            </div>
            <div class="width-120 text-center ml-2" *ngIf="+element.total < 0">
                <span class="color-dark-grey">{{ 'Accounting.invoice.storno'| translate }}</span>
            </div>
            <div class="width-120 text-center ml-2" *ngIf="element.payment_status === 2 || +element.total === 0">
                <span class="color-dark-grey">{{ 'Accounting.invoice.paid'| translate }}</span>
            </div>
            <div *ngIf="element.payment_status === 1" class="width-120 text-center ml-2">
                <span class="color-dark-grey">{{ 'Accounting.invoice.inprogress'| translate }}</span>
            </div>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3"
        [ngClass]="{ 'invoice-selected' : selection.indexOf(row) !== -1, 'color-green-primary': row.payment} ">
    </mat-row>
</mat-table>
<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
<div class="flex-center spinner-overlay" *ngIf="spinnerVisible">
    <mat-progress-spinner class="spinner" color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>